import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';

import { retrieve, full_transfer, info_transfer, deny_transfer_request, delete_transfer_request } from './actions/AdminRequestStaffTransfer'
import CalenderView from './components/CalendarView';

export default function AdminRequestStaffTransfer() {
  const { server_id, admin_id } = useParams();
  const [IncomingStaffTransferData, setIncomingStaffTransferData] = useState([]);
  const [RequestType, setRequestType] = useState("incoming");
  const [loading, setLoading] = useState(true);


  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    fetchAdminRequestStaffTransfer(server_id, admin_id, RequestType)
  }, [server_id, admin_id, RequestType]);

  let fetchAdminRequestStaffTransfer = (server_id, admin_id, type) => {
    let data = {
      server_id: server_id ? server_id : '',
      type: type,
      username: admin_id ? admin_id : '',
      limit: 1,
      last_id: '',
      first_id: '',
    };
    dispatch(retrieve(data)).then((response) => {
      setLoading(false);
      console.log(response)
      // let tempIncomingStaffTransferData = [];
      // let start_date = '';
      // let end_date = '';
      // for(let i=0; i < response.length; i++) {
      //   start_date = formatDateTime(response[i]['data']['date'], response[i]['data']['timeRange']['from'], 'd/m/y H:i a')
      //   end_date = formatDateTime(response[i]['data']['date'], response[i]['data']['timeRange']['to'], 'd/m/y H:i a')
      //   // console.log(new Date(response[i]['data']['startedDate']).toISOString())
      //   tempIncomingStaffTransferData.push({
      //     end: end_date,
      //     resourceId: response[i]['id'],
      //     start: start_date,
      //     title: response[i]['data']['userID']
      //   })
      // }
      // // console.log(tempIncomingStaffTransferData)
      setIncomingStaffTransferData(response);
    });
  }

  let handleRequestTypeChange = (e) => {
    // console.log()
    let type = e.target.value;
    setRequestType(e.target.value);
    fetchAdminRequestStaffTransfer(server_id, admin_id, type);
  }


  let handleStaffTransferActionTaken = (val, action) => {
    let data = {}
    if(action == 'full_transfer') {
      // console.log(val)
      data = {
        request_id: val.request_id,
      };
      dispatch(full_transfer(data)).then((response) => {
        setLoading(false);
        // setIncomingStaffTransferData(response);
        data = {
          server_id: server_id ? server_id : '',
          type: RequestType,
          username: admin_id ? admin_id : '',
          limit: 1,
          last_id: '',
          first_id: '',
        };
        dispatch(retrieve(data)).then((response) => {
          setLoading(false);
          setIncomingStaffTransferData(response);
        });
      });
    } if(action == 'info_transfer') {
      // console.log(val)
      data = {
        request_id: val.request_id,
      };
      dispatch(info_transfer(data)).then((response) => {
        setLoading(false);
        // setIncomingStaffTransferData(response);
        data = {
          server_id: server_id ? server_id : '',
          type: RequestType,
          username: admin_id ? admin_id : '',
          limit: 1,
          last_id: '',
          first_id: '',
        };
        dispatch(retrieve(data)).then((response) => {
          setLoading(false);
          setIncomingStaffTransferData(response);
        });
      });
    } if(action == 'deny') {
      // console.log(val)
      data = {
        request_id: val.request_id,
        status: 'denied',
      };
      dispatch(deny_transfer_request(data)).then((response) => {
        setLoading(false);
        // setIncomingStaffTransferData(response);
        data = {
          server_id: server_id ? server_id : '',
          type: RequestType,
          username: admin_id ? admin_id : '',
          limit: 1,
          last_id: '',
          first_id: '',
        };
        dispatch(retrieve(data)).then((response) => {
          setLoading(false);
          setIncomingStaffTransferData(response);
        });
      });
    } if(action == 'delete') {
      // console.log(val)
      data = {
        request_id: val.request_id,
        status: 'deleted',
      };
      dispatch(delete_transfer_request(data)).then((response) => {
        setLoading(false);
        // setIncomingStaffTransferData(response);
        data = {
          server_id: server_id ? server_id : '',
          type: RequestType,
          username: admin_id ? admin_id : '',
          limit: 1,
          last_id: '',
          first_id: '',
        };
        dispatch(retrieve(data)).then((response) => {
          setLoading(false);
          setIncomingStaffTransferData(response);
        });
      });
    }
  }

  let handleStaffTransferPopup = () => {
    
  }


  // let formatDateTime = (date, time, format) => {
  //   if(date && date.length > 0 && time && time.length > 0 && format == 'd/m/y H:i a') {
  //     // console.log(date, time)
  //     let tempDay = date.split('/')[0]
  //     let tempMonth = date.split('/')[1]
  //     let tempYear = date.split('/')[2]
  //     let time_ = time.split(' ')[0];
  //     let hour = time_.split(':')[0];
  //     hour = parseInt(time_.split(':')[0]);
  //     let minute = time_.split(':')[1];
  //     if(time.split(' ')[1] == 'PM') {
  //       return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? hour < 10 ? hour + 12 : hour + 12 : hour) + ':' + minute + ':00').toISOString();
  //     } else {
  //       return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? '0' + hour : hour) + ':' + minute + ':00').toISOString();
  //     }
  //   } else {
  //     return new Date().toISOString();
  //   }
  // }
  return (
    <>
      {loading ?
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
        :
        <>
          <div className='container-fluid'>
            <div className='row mb-2'>
              <div className='col-md-3'>
                <select className='form-select' onChange={handleRequestTypeChange} value={RequestType}>
                  <option value={'incoming'}>Incoming</option>
                  <option value={'outgoing'}>Outgoing</option>
                </select>
              </div>
              {/* <div className='offset-md-6 col-md-3 text-right'>
                <button className='btn btn-primary' type='button' onClick={handleStaffTransferPopup}>Start Staff Transfer</button>
              </div> */}
            </div>
            <div className='row'>
              {IncomingStaffTransferData && IncomingStaffTransferData?.data && IncomingStaffTransferData?.data.length > 0 ?
                IncomingStaffTransferData.data.map((val, index) =>
                  <div className='col-md-4'>
                    <div className='card'>
                      <div className='card-body'>
                        <i className={RequestType === 'incoming' ? 'fa fa-arrow-left float-right rotate-30deg fg-green' : 'fa fa-arrow-right float-right rotate-30deg fg-theme'}></i>
                        <h4 className='fg-theme'>{val?.staff_id}</h4>
                        <p>Status : {val?.status}</p>
                        <hr />
                        <p><b>From: </b>{val?.prev_server_id}</p>
                        <p><b>To: </b>{val?.new_server_id}</p>
                        {val?.status == 'pending' && RequestType === 'incoming' ?
                          <div className='row mt-1'>
                            <hr />
                            <div className='col-md-3'>
                              <button className='btn btn-sm btn-theme btn-warning' type='button' onClick={() => handleStaffTransferActionTaken(val, 'full_transfer')}>Full Transfer</button>
                            </div>
                            <div className='col-md-6 text-center'>
                              <button className='btn btn-sm btn-theme btn-success' type='button' onClick={() => handleStaffTransferActionTaken(val, 'info_transfer')}>Information Transfer</button>
                            </div>
                            {/* <div className='col-md-2 text-center'>
                              <button className='btn btn-sm btn-theme btn-warning' type='button' onClick={() => handleStaffTransferActionTaken(val, 'deny')}>Deny</button>
                            </div> */}
                            <div className='col-md-3 text-right'>
                              <button className='btn btn-sm btn-theme btn-danger' type='button' onClick={() => handleStaffTransferActionTaken(val, 'delete')}>Delete</button>
                            </div>
                          </div>
                          : ''}
                      </div>
                    </div>
                  </div>
                )
                : ''
              }
            </div>
          </div>
        </>
      }
    </>
  )
}
