import React from 'react'

export default function ServiceUserListTabs({post, ModalId, TabId, showModalAndTab}) {
    return (
        <>
            <div className={`modal fade ${ModalId=='ServiceUserListTabs' ? 'show' : ''}`} id="ServiceUserListTabs" tabindex="-1" role="dialog"
                aria-labelledby="ServiceUserListTabsLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="ServiceUserListTabsLabel">Event</h5>
                            <button type="button" className="close" onClick={() => showModalAndTab('', '')}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{maxHeight: "50vh", overflowY: "scroll"}}>
                            <div className="card card-primary mt-2">
                                <div className="card-body">
                                    <ul className="nav nav-tabs" id="ServiceUserTab" role="tablist">
                                        <li className="nav-item">
                                            <a className={`nav-link ${TabId=='care-days-tab' ? ' active' : ''}`} id="care-days-tab" onClick={() => showModalAndTab('ServiceUserListTabs', 'care-days-tab')}>Care Days</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link ${TabId=='activities-tab' ? ' active' : ''}`} id="activities-tab" onClick={() => showModalAndTab('ServiceUserListTabs', 'activities-tab')}>Activities</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link ${TabId=='medications-tab' ? ' active' : ''}`} id="medications-tab" onClick={() => showModalAndTab('ServiceUserListTabs', 'medications-tab')}>Medications</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link ${TabId=='medicine-stocks-tab' ? ' active' : ''}`} id="medicine-stocks-tab" onClick={() => showModalAndTab('ServiceUserListTabs', 'medicine-stocks-tab')}>Medicine Stocks</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div className={`tab-pane fade ${TabId=='care-days-tab' ? ' show active' : ''}`} id="care-days" role="tabpanel" aria-labelledby="care-days-tab"
                                            style={{maxHeight: "300px", overflowY: "scroll"}}>
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="care-days-list">
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className={`tab-pane fade ${TabId=='activities-tab' ? ' show active' : ''}`} id="activities" role="tabpanel" aria-labelledby="activities-tab"
                                            style={{maxHeight: "300px", overflowY: "scroll"}}>
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="activity-list">
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className={`tab-pane fade ${TabId=='medications-tab' ? ' show active' : ''}`} id="medications" role="tabpanel" aria-labelledby="medications-tab"
                                            style={{maxHeight: "300px", overflowY: "scroll"}}>
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="medications-list">
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className={`tab-pane fade ${TabId=='medicine-stocks-tab' ? ' show active' : ''}`} id="medicine-stocks" role="tabpanel" aria-labelledby="medicine-stocks-tab"
                                            style={{maxHeight: "300px", overflowY: "scroll"}}>
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="medicine-stocks-list">
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
