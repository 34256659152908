import React, { useState } from 'react'
import { useParams } from 'react-router-dom';

export default function CreateNewCareDaysModal({ post, ServiceUser, ModalId, setModalId, TabId, setTabId, showModalAndTab }) {
    const { server_id, admin_id } = useParams();
    const [FilterPayload, setFilterPayload] = useState({});

    const handleChange = e => {
        const { name, value } = e?.target;
        setFilterPayload({
            ...FilterPayload,
            [name]: value
        })
    }

    const handleChecked = e => {
        const { name, checked, value } = e?.target;
        if (checked) {
            setFilterPayload({
                ...FilterPayload,
                [name]: value
            })
        } else {
            setFilterPayload({
                ...FilterPayload,
                [name]: ''
            })
        }
    }


    const convertTo12HourFormat = (time24) => {
        // Split the input time into hours and minutes
        let [hours, minutes] = time24.split(':').map(Number);

        // Determine AM or PM suffix
        const period = hours >= 12 ? 'PM' : 'AM';

        // Convert 24-hour format to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12;  // Handle case for 0 (midnight)

        // Format minutes as a two-digit string
        hours = hours < 10 ? `0${hours}` : hours;
        minutes = minutes < 10 ? `0${minutes}` : minutes;

        // Return the formatted time in 12-hour format
        return `${hours}:${minutes} ${period}`;
    }


    const convertTo24HourFormat = (time24) => {
        // Split the input time into hours and minutes
        let [hours, minutes, seconds] = time24.split(':').map(Number);

        // Determine AM or PM suffix
        const period = hours >= 12 ? 12 : 0;

        // Convert 24-hour format to 12-hour format
        // hours = hours % 12;
        hours = hours ? hours : 12;  // Handle case for 0 (midnight)

        hours = hours < 10 ? `0${hours}` : hours;

        // Format minutes as a two-digit string
        minutes = minutes < 10 ? `0${minutes}` : minutes;

        seconds = seconds < 10 ? `0${seconds}` : seconds;

        // Return the formatted time in 12-hour format
        return `${hours}:${minutes}:${seconds}`;
    }

    const updateCareDays = async () => {
        const endpoint = "employees/user_care_days/update";
        const filter = {
            id: $("#id").val(),
            server_id: server_id,
            service_user_id: FilterPayload?.service_user_id,
            year: FilterPayload?.start_date.split('-')[0],
            month: FilterPayload?.start_date.split('-')[1],
            start_date: FilterPayload?.start_date,
            start_time: convertTo12HourFormat(FilterPayload?.start_time),
            end_date: FilterPayload?.end_date,
            end_time: convertTo12HourFormat(FilterPayload?.end_time),
        };
        try {
            const response = await post(endpoint, filter);
            if (response?.res) {
                alert("Care Day Successfully Updated.");
                if ($("#manageForUserType").val() == 'USER') {
                    // await syncCareDays($("#service_user_filter").val(), $("#service_user_filter option:selected").text());
                    // await syncEvents($("#service_user_filter").val(), $("#service_user_filter option:selected").text(), $("#visit_category_for_service_user").val(), $("#visit_status_for_service_user").val());
                }


                if ($("#manageForUserType").val() == 'STAFF') {
                    // await syncWorkDays($("#employee_filter").val(), $("#employee_filter option:selected").text());
                    // await syncLeaves($("#employee_filter").val(), $("#employee_filter option:selected").text());
                    // await syncEmployeeEvents($("#employee_filter").val(), $("#employee_filter option:selected").text(), $("#schedule_type").val(), $("#visit_status_for_employee").val());
                }
            } else {
                alert(response?.msg)
            }
        } catch (error) {
            console.error('Error fetching data:', error);  // Handle any errors
        }
    }

    const saveCareDays = async () => {
        const endpoint = "employees/user_care_days/create";
        const filter = {
            server_id: server_id,
            service_user_id: FilterPayload?.service_user_id,
            year: FilterPayload?.start_date.split('-')[0],
            month: FilterPayload?.start_date.split('-')[1],
            start_date: FilterPayload?.start_date,
            start_time: convertTo12HourFormat(FilterPayload?.start_time),
            end_date: FilterPayload?.end_date,
            end_time: convertTo12HourFormat(FilterPayload?.end_time),
        };
        try {
            const response = await post(endpoint, filter);
            if (response?.res) {
                alert("Care Day Successfully Saved.");
                if ($("#manageForUserType").val() == 'USER') {
                    // await syncCareDays($("#service_user_filter").val(), $("#service_user_filter option:selected").text());
                    // await syncEvents($("#service_user_filter").val(), $("#service_user_filter option:selected").text(), $("#visit_category_for_service_user").val(), $("#visit_status_for_service_user").val());
                }


                if ($("#manageForUserType").val() == 'STAFF') {
                    // await syncWorkDays($("#employee_filter").val(), $("#employee_filter option:selected").text());
                    // await syncLeaves($("#employee_filter").val(), $("#employee_filter option:selected").text());
                    // await syncEmployeeEvents($("#employee_filter").val(), $("#employee_filter option:selected").text(), $("#schedule_type").val(), $("#visit_status_for_employee").val());
                }
            } else {
                alert(response?.msg)
            }
        } catch (error) {
            console.error('Error fetching data:', error);  // Handle any errors
        }
    }


    return (
        <>
            <div className={`modal fade ${ModalId == 'createNewCareDaysModal' ? 'show' : ''}`} id="createNewCareDaysModal" tabindex="-1" role="dialog"
                aria-labelledby="createNewCareDaysModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="createNewCareDaysModalLabel">Care Days</h5>
                            <button type="button" className="close" onClick={() => showModalAndTab('', '')}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <input type="hidden" name="year_CareDays" />
                                <input type="hidden" name="month_CareDays" />
                                <div className="form-group">
                                    <label>Service User</label>
                                    <select className="form-select" id="service_user_CareDays" onChange={handleChange}>
                                        <option value=""> - Select - </option>
                                        {
                                            ServiceUser && ServiceUser?.length > 0 ?
                                                ServiceUser?.map((val, index) => (
                                                    <option value={`${val?.username}`}> {`${val?.full_name} (${val?.email})`} </option>
                                                ))
                                                : ''
                                        }
                                    </select>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Start Date</label>
                                            <input className="form-control" type="date" id="start_date_CareDays" onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Start Time</label>
                                            <input className="form-control" type="time" id="start_time_CareDays" onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>End Date</label>
                                            <input className="form-control" type="date" id="end_date_CareDays" onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>End Time</label>
                                            <input className="form-control" type="time" id="end_time_CareDays" onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => showModalAndTab('', '')}>Close</button>
                            <button type="button" className="btn btn-primary" id="saveServiceUserCareDays" onClick={saveCareDays}>Save</button>
                            <button type="button" className="btn btn-primary" id="updateServiceUserCareDays" onClick={updateCareDays}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
