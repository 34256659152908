import React from 'react'

export default function EmployeeListTabs({post, ModalId, TabId, showModalAndTab}) {
    return (
        <>
            <div className={`modal fade ${ModalId=='EmployeeListTabs' ? 'show' : ''}`} id="EmployeeListTabs" tabindex="-1" role="dialog" aria-labelledby="EmployeeListTabsLabel"
                aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="EmployeeListTabsLabel">Event</h5>
                            <button type="button" className="close" onClick={() => showModalAndTab('', '')}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{maxHeight: "50vh", overflowY: "scroll"}}>
                            <div className="card card-primary mt-2">
                                <div className="card-body">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <a className={`nav-link ${TabId=='work-days-tab' ? 'show active' : ''}`} id="work-days-tab" onClick={() => showModalAndTab('EmployeeListTabs', 'work-days-tab')}>Work Days</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link ${TabId=='leaves-tab' ? 'show active' : ''}`} id="leaves-tab" onClick={() => showModalAndTab('EmployeeListTabs', 'leaves-tab')}>Leaves</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div className={`tab-pane fade ${TabId=='work-days-tab' ? 'show active' : ''}`} id="work-days" role="tabpanel" aria-labelledby="work-days-tab"
                                            style={{maxHeight: "300px", overflowY: "scroll"}}>
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="work-days-list">
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className={`tab-pane fade ${TabId=='leaves-tab' ? 'show active' : ''}`} id="leaves" role="tabpanel" aria-labelledby="leaves-tab"
                                            style={{maxHeight: "300px", overflowY: "scroll"}}>
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="leave-days-list">
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
