import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';

import { retrieve, update } from './actions/Message'

export default function Message() {
  const { server_id, admin_id } = useParams();
  const [MessageData, setMessageData] = useState([]);
  const [loading, setLoading] = useState(true);


  const dispatch = useDispatch();
  useEffect(() => {
    fetchMessage(server_id, admin_id);
  }, [server_id, admin_id]);


  let fetchMessage = (server_id, admin_id) => {
    setLoading(true);
    let data = {
      server_id: server_id ? server_id : '',
      admin_id: admin_id ? admin_id : '',
      limit: 1,
      last_id: '',
      first_id: '',
    };
    dispatch(retrieve(data)).then((response) => {
      setLoading(false);
      // console.log(response)
      setMessageData(response?.data);
    });
  }

  let handleAccess = (device_id, access) => {
    let data = {
      server_id: server_id ? server_id : '',
      admin_id: admin_id ? admin_id : '',
      device_id: device_id,
      access: access,
    }
    dispatch(update(data)).then((response) => {
      // console.log(response);
      setLoading(false);
      fetchMessage(server_id, admin_id);
    });
  }


  let formatDateTime = (date) => {
    return date;
    console.log(date)
    // if(date && date.length > 0 && time && time.length > 0 && format == 'd/m/y H:i a') {
    //   // console.log(date, time)
    //   let tempDay = date.split('/')[0]
    //   let tempMonth = date.split('/')[1]
    //   let tempYear = date.split('/')[2]
    //   let time_ = time.split(' ')[0];
    //   let hour = time_.split(':')[0];
    //   hour = parseInt(time_.split(':')[0]);
    //   let minute = time_.split(':')[1];
    //   if(time.split(' ')[1] == 'PM') {
    //     return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? hour < 10 ? hour + 12 : hour + 12 : hour) + ':' + minute + ':00').toISOString();
    //   } else {
    //     return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? '0' + hour : hour) + ':' + minute + ':00').toISOString();
    //   }
    // } else {
    //   return new Date().toISOString();
    // }
  }
  return (
    <>
      {loading ?
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
        :
        <>
          <div className='row'>
            <h4 className='fg-theme mb-2 mt-2'>Message</h4>
            {
              MessageData && MessageData?.length > 0 ?
                MessageData.map((val, index) => {
                  return(
                    <div className='col-md-6 col-lg-4 col-sm-12 mt-1'>
                      <div className='card'>
                        <div className='card-body'>
                          <h4 className='fg-theme mb-2 mt-2'>{val?.data?.chatID ?  val?.data?.chatID : ''}</h4>
                          {/* <h4 className='fg-theme mb-2 mt-2'>{val?.data?.admin_user ? val?.data?.admin_user ? val?.data?.admin_user : '' : ''}</h4> */}
                          <p>Last Message : {val?.data?.lastMessage ? val?.data?.lastMessage : ''}</p>
                          <p>Last Message By: {val?.data?.lastMessageBy ? val?.data?.lastMessageBy : ''}</p>
                          <p>Last Message At: {Date(val?.data?.lastMessageAt ? val?.data?.lastMessageAt : '')}</p>
                          <p>Server ID : {val?.data?.serverID ? val?.data?.serverID : ''}</p>
                          <p>Admin : {val?.data?.adminID ? val?.data?.adminID : ''}</p>
                          <p>User : {val?.data?.userID ? val?.data?.userID : ''}</p>
                          {/* <p>Document : {val?.data?.document ? val?.data?.document : ''}</p> */}
                          <p>Created At : {Date(val?.data?.createdAt ? val?.data?.createdAt : '')}</p>
                          {/* <p>Deleted : <i className={`fa fa-${!val?.status?'toggle-on fg-green':'toggle-off fg-red'}`}></i></p>
                          <p>Status : <span className='text-uppercase'>{val?.status}</span></p> */}
                          {/* <p>Access : {val.access?'TRUE':'FALSE'}</p> */}
                        </div>
                      </div>
                    </div>
                  )
                })
              : ''
            }
          </div>
        </>
      }
    </>
  )
}