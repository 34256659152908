import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { NavLink, useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';


import 'sweetalert/dist/sweetalert.css';

import SweetAlert from 'sweetalert-react';

import Popup from '../../../components/popup';

import {
    retrieve, trash, franchiseApproval
} from './actions/arcServers';

import ArchitectureServersAddModal from './architectureServers_add'

export default function ArchitectureServers() {
    const { server_id } = useParams();
    const server_data = useSelector(state => state.ARCServersReducer, shallowEqual);
    // console.log(server_data)
    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    // For Pagination Part
    const [firstIdHandlerForPagination, setFirstIdHandlerForPagination] = useState([]);
    const [lastIdHandlerForPagination, setLastIdHandlerForPagination] = useState([]);
    // For Pagination Part

    let handleCreateModalVisibility = (is_edit = 0, record) => {
        // if(is_edit)

        setCreateModalVisibility(!createModalVisibility);
    }

    let updateParentState = () => {
        setCreateModalVisibility(!createModalVisibility);
    }
    const initial_single_data = {
        id: '',
        record_data: {}
    };
    const [single_data, setSingleData] = useState(initial_single_data);
    const [record_length, setRecordLength] = useState(10);
    const [page, setPage] = useState(0);
    const [showTrashAlert, setShowTrashAlert] = useState({ show: false });
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    const fetchServers = (recordLength) => {
        setLoading(true);
        let data = {
            limit: parseInt(recordLength),
            last_id: '',
            first_id: '',
        };
        dispatch(retrieve(data)).then((response) => {
            setLoading(false);
            // For Pagination Part
            let firstIDArr = firstIdHandlerForPagination;
            let lastIDArr = lastIdHandlerForPagination;
            firstIDArr.push(response && response.first_id);
            lastIDArr.push(response && response.last_id);
            setFirstIdHandlerForPagination(firstIDArr);
            setLastIdHandlerForPagination(lastIDArr);
            // For Pagination Part
        });
    }

    useEffect(() => {
        fetchServers(record_length);
    }, []);

    let handleChange = (e) => {
        if (e.target.name == 'record_length') {
            setRecordLength(e.target.value);
            fetchServers(e.target.value);
        }
    }

    let handlePrev = () => {
        setLoading(true);
        let data = {
            limit: record_length,
            last_id: '',
            first_id: firstIdHandlerForPagination[firstIdHandlerForPagination.length - 2],
        };
        dispatch(retrieve(data)).then(() => {
            setPage(page > 0 ? page - 1 : page)
            setLoading(false)
            // For Pagination Part
            let firstIDArr = firstIdHandlerForPagination.slice(0, firstIdHandlerForPagination.length - 1);
            let lastIDArr = lastIdHandlerForPagination.slice(0, lastIdHandlerForPagination.length - 1);
            setFirstIdHandlerForPagination(firstIDArr);
            setLastIdHandlerForPagination(lastIDArr);
            // For Pagination Part
        });
    }
    let handleNext = () => {
        setLoading(true);
        setPage((
            page <= (
                parseInt((
                    server_data &&
                        server_data.data &&
                        server_data.data.total ? server_data.data.total : 1
                ) / record_length) + 1
            ))
            ? page + 1
            : page
        );
        let data = {
            limit: record_length,
            last_id: server_data.data.last_id,
            first_id: '',
        };
        dispatch(retrieve(data)).then((response) => {
            // For Pagination Part
            let firstIDArr = firstIdHandlerForPagination;
            let lastIDArr = lastIdHandlerForPagination;
            firstIDArr.push(response && response.first_id);
            lastIDArr.push(response && response.last_id);
            setFirstIdHandlerForPagination(firstIDArr);
            setLastIdHandlerForPagination(lastIDArr);
            // For Pagination Part
            setLoading(false)
        });
    }







    let handleEdit = (record) => {
        updateParentState();
        setSingleData(
            {
                id: record.id,
                record_data: record
            }
        )
        // console.log(single_data)
    }

    let handleShowTrashAlert = (uid) => {
        // console.log(showTrashAlert, uid)
        setShowTrashAlert({ uid: uid, show: !showTrashAlert.show });
    }

    let handleAlertResponse = () => {
        handleDelete();
    }

    let handleDelete = () => {
        setLoading(true);
        let data = {
            id: showTrashAlert.uid,
        };
        dispatch(trash(data)).then(() => {
            setLoading(true);
            data = {
                limit: record_length,
                last_id: '',
                first_id: '',
            };
            dispatch(retrieve(data)).then(() => {
                setLoading(false);
                setTimeout(() => {
                    setShowTrashAlert({ show: !showTrashAlert.show });
                }, 1000);
            });
        });
    }

    let handleApproveFranchiseAccess = (server_id) => {
        let data = {
            server_id: server_id,
        };
        dispatch(franchiseApproval(data)).then(() => {
            data = {
                limit: record_length,
                last_id: '',
                first_id: '',
            };
            dispatch(retrieve(data)).then(() => {
                setLoading(false);
            });
        });
    }


    return (
        <>
            {loading ?
                <div>
                    <Placeholder.Paragraph rows={8} />
                    <Loader center content="loading" />
                </div>
                :
                <>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h3 className="text-dark mb-0">Servers</h3>
                        <a className="btn btn-primary btn-sm ml-15 d-flex" role="button" href="javascript:void(0)" onClick={handleCreateModalVisibility}>
                            <i className="fas fa-plus fa-sm p-1"></i>
                            <span className='hidden-xs'>&nbsp;Add New Server</span>
                        </a>
                        <Popup visibility_status={createModalVisibility} updateParentState={updateParentState}><ArchitectureServersAddModal updateParentState={updateParentState} /></Popup>
                    </div>
                    <div className="card shadow hidden-xs">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6 text-nowrap">
                                    <div id="dataTable_length" className="dataTables_length" aria-controls="dataTable">
                                        <label className="form-label">Show&nbsp;
                                            <select className="d-inline-block form-select form-select-sm" name='record_length' defaultValue={record_length} onChange={handleChange}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                                <table className="table my-0" id="dataTable">
                                    <thead>
                                        <tr>
                                            <th>Franchise Code</th>
                                            <th>Server Name</th>
                                            <th>Total Admins</th>
                                            <th>Total Users</th>
                                            <th>Total Employees</th>
                                            <th>Phone Number</th>
                                            <th>Company Address</th>
                                            <th style={{ width: "200px" }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            server_data &&
                                                server_data.data &&
                                                // server_data.data.length > 0 && 
                                                server_data.data.servers &&
                                                server_data.data.servers.length > 0 ?
                                                server_data.data.servers.map((val, index) => {
                                                    return (
                                                        <tr>
                                                            <th>
                                                                {val?.franchise_code && val?.franchise_code.length > 0 ?
                                                                    val.franchise_code
                                                                    :
                                                                    <i className='fa fa-plus-circle p-cursor' onClick={() => handleApproveFranchiseAccess(val.server_id)}></i>
                                                                }
                                                            </th>
                                                            <td>{val.server_id}</td>
                                                            <td>{val.total_admins}</td>
                                                            <td>{val.total_users}</td>
                                                            <td>{val.total_employees}</td>
                                                            <td>{val.phone_number}</td>
                                                            <td>{val.company_address}</td>
                                                            <td>
                                                                {/* <button type='button' className='btn btn-light btn-sm mr-15'><i className="fas fa-map-marker text-blue"></i></button> */}
                                                                <NavLink to={`/Architecture/Servers/${val.server_id}/Edit`}><button type='button' className='btn btn-light btn-sm mr-15'><i className="fas fa-edit"></i></button></NavLink>
                                                                {/* <button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleCreateModalVisibility(1, val)}><i className="fas fa-edit"></i></button> */}
                                                                {/* Pradeepto Da company r applicant er delete hide kore dao karon etay kichu complicated process ache same architecture er server. staff. user. admin */}
                                                                {/* <button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleShowTrashAlert(val.id)}><i className="fas fa-trash fg-theme"></i></button> */}
                                                                <NavLink to={`/Architecture/Servers/${val.server_id}/Details`}><button type='button' className='btn btn-light btn-sm mr-15'><i className="fas fa-angle-right fg-theme"></i></button></NavLink>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                : ''
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-md-6 align-self-center">
                                    <p id="dataTable_info" className="dataTables_info" role="status" aria-live="polite">Total Records {server_data && server_data.data && server_data.data.total ? server_data.data.total : 0}</p>
                                </div>
                                <div className="col-md-6">
                                    <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                        <ul className="pagination">
                                            {page ?
                                                <li className={page ? "page-item" : "page-item disabled"} onClick={handlePrev}><a className="page-link" aria-label="Previous" href="#"><span aria-hidden="true">«</span> Previous</a></li>
                                                : ''}
                                            {(page <= (parseInt((server_data && server_data.data && server_data.data.total ? server_data.data.total : 1) / record_length) - 1)) ?
                                                <li className={(page <= (parseInt((server_data && server_data.data && server_data.data.total ? server_data.data.total : 1) / record_length) - 1)) ? "page-item" : "page-item disabled"} onClick={handleNext}><a className="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span></a></li>
                                                : ''}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <SweetAlert
                                show={showTrashAlert.show}
                                title=""
                                text="Are you sure you want to permanently delete this record?"
                                onCancel={() => handleShowTrashAlert(false)}
                                showCancelButton
                                onConfirm={handleAlertResponse}
                            />
                        </div>
                    </div>
                    <div className="row shown-xs">
                        <div className="container-fluid text-nowrap">
                            <div id="dataTable_length" className="dataTables_length" aria-controls="dataTable">
                                <label className="form-label">Show&nbsp;
                                    <select className="d-inline-block form-select form-select-sm" name='record_length' defaultValue={record_length} onChange={handleChange}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                {
                                    server_data &&
                                        server_data.data &&
                                        // server_data.data.length > 0 && 
                                        server_data.data.servers &&
                                        server_data.data.servers.length > 0 ?
                                        server_data.data.servers.map((val, index) => {
                                            return (
                                                <div className="col-md-6 mt-2">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            {/* <b>Actions : </b> */}
                                                            {val?.franchise_code && val?.franchise_code.length > 0 ?
                                                                <b className='mt-2'>F. Code: {val.franchise_code}</b>
                                                                :
                                                                <span>Add Franchise &nbsp; &nbsp; <button type='button' className='btn'><i className='fa fa-plus-circle p-cursor' onClick={() => handleApproveFranchiseAccess(val.server_id)}></i></button></span>
                                                            }
                                                            <div className='align-right'>
                                                                {/* <button type='button' className='btn btn-light btn-sm mr-15'><i className="fas fa-map-marker text-blue"></i></button> */}
                                                                <NavLink to={`/Architecture/Servers/${val.server_id}/Edit`}><button type='button' className='btn btn-light btn-sm mr-15'><i className="bi bi-pencil"></i></button></NavLink>
                                                                {/* <button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleCreateModalVisibility(1, val)}><i className="fas fa-edit"></i></button> */}
                                                                {/* Pradeepto Da company r applicant er delete hide kore dao karon etay kichu complicated process ache same architecture er server. staff. user. admin */}
                                                                {/* <button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleShowTrashAlert(val.id)}><i className="fas fa-trash fg-theme"></i></button> */}
                                                                <NavLink to={`/Architecture/Servers/${val.server_id}/Details`}><button type='button' className='btn btn-light btn-sm mr-15'><i className="fas fa-angle-right fg-theme"></i></button></NavLink>
                                                            </div>
                                                        </div>
                                                        <div className="card-body shown-xs">
                                                            <br />
                                                            <b>Server Name	: </b>
                                                            <h5>{val.server_id}</h5>
                                                            <br />
                                                            <p><b>Total Admins : </b>{val.total_admins}</p>
                                                            <p><b>Total Users : </b>{val.total_users}</p>
                                                            <p><b>Total Employees : </b>{val.total_employees}</p>
                                                            <b>Phone Number : </b>
                                                            <p>{val.phone_number}</p>
                                                            <b>Company Address : </b>
                                                            <p>{val.company_address}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : ''
                                }
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 align-self-center">
                                    <p id="dataTable_info" className="dataTables_info text-center" role="status" aria-live="polite">Total Records {server_data && server_data.data && server_data.data.total ? server_data.data.total : 0}</p>
                                </div>
                                <div className="col-md-12 text-right">
                                    <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                        <ul className="pagination">
                                            {page ?
                                                <li className={page ? "page-item" : "page-item disabled"} onClick={handlePrev}><a className="page-link" aria-label="Previous" href="#"><span aria-hidden="true">«</span> Previous</a></li>
                                                : ''}
                                            {(page <= (parseInt((server_data && server_data.data && server_data.data.total ? server_data.data.total : 1) / record_length) - 1)) ?
                                                <li className={(page <= (parseInt((server_data && server_data.data && server_data.data.total ? server_data.data.total : 1) / record_length) - 1)) ? "page-item align-right" : "page-item align-right disabled"} onClick={handleNext}><a className="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span></a></li>
                                                : ''}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
