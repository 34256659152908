import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";


import {
    updateEmail, retrieve
} from './actions/arcServerAdmin';
import { useParams } from 'react-router-dom';



export default function ArchitectureServerAdmin_email(props) {
    const {server_id} = useParams();
    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    const [ FormData, setFormData ] = useState(props?.singleValueEmail);
    const [ Email, setEmail ] = useState('');
    

    const dispatch = useDispatch();


    useEffect(() => {
        setFormData(props?.singleValueEmail)
        setEmail("");
    }, [props?.singleValueEmail])

    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
        props?.updateParentStateEmail(false)
    }

    let handleChange = (e) => {
        const { name, value } = e.target;
        setEmail(value);
    }

    let handleUpdate = () => {
        console.log(FormData)
        let data = {
            uid: FormData?.uid,
            username: FormData?.username,
            new_email: Email,
            old_email: FormData?.email,
            server_id: server_id,
        };
        dispatch(updateEmail(data)).then((response) => {
            alert(response.data.msg);
            props.updateParentStateEmail(false);
            setCreateModalVisibility(false);
            data = {
                server_id: server_id,
                limit: 10,
                last_id: '',
                first_id: '',
            };
            dispatch(retrieve(data)).then(() => { });
            setEmail("");
        })
    }


    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='container-fluid mt-4 mb-4'>
                    <div className='row'>
                        <div className='container-fluid mb-4'>
                            <i className='fa fa-times float-right' onClick={handleCreateModalVisibility}></i>
                            <h3 className='fg-theme text-left mob-popup-fs'>Update Admin Email</h3>

                        </div>
                        <hr />
                        <div className='container-fluid'>
                            <div className='form-group mt-2 mb-2'>
                                <label className='mt-2 mb-2'>Your old email id: {props?.singleValueEmail?.email}</label>
                            </div>
                            <div className='form-group mt-2 mb-2'>
                                <label className='mt-2 mb-2'>Email ID</label>
                                <input type='text' className='form-control' name='email' placeholder='Enter new email id' value={Email} onChange={handleChange} />
                            </div>
                        </div>
                        <hr />
                        <div className='container-fluid'>
                            <button className='btn btn-primary btn-theme' type='button' onClick={handleCreateModalVisibility}>Close</button>
                            <button className='btn btn-primary btn-theme float-right' type='button' onClick={handleUpdate}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
