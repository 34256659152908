import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Avatar from '../../../assets/avatars/avatar1.jpeg'
import { Loader, Placeholder } from 'rsuite';

import Iframe from 'react-iframe';
// import '../Extras/profile.css';
import ServerServiceUserVisitStatisticsGraph from '../architectureDashboard/components/graphs/ServiceUserVisitStatistics/ServiceUserVisitStatistics'
import { NavLink, useParams } from 'react-router-dom';


import {
  retrieveSingle
} from "./actions/arcServerStaff";


export default function ArchitectureStaffDetails(props) {
  const InitialData = {

  };
  const { server_id, staff_id } = useParams();
  let [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(InitialData);
  const [statusData, setStatusData] = useState({});
  const [date_of_birth, setDateOfBirth] = useState('')
  const dispatch = useDispatch();

  useEffect(() => {
    let data = {
        server_id: server_id?server_id:'',
        username: staff_id?staff_id:'',
        limit: 1,
        last_id: '',
        first_id: '',
    };
    dispatch(retrieveSingle(data)).then((response) => {
      setLoading(false);
      setFormData(response);

      let monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      let x = response?.dob ? response?.dob : '';
      let xdate;
      if(x.length > 0)
        xdate = new Date(x).toISOString().split('T')[0];
      else
        xdate = new Date().toISOString().split('T')[0];
      let xyear = xdate.split('-')[0];
      let xmonth = monthArr[parseInt(xdate.split('-')[1]) - 1];
      let xday = xdate.split('-')[2];
      setDateOfBirth(xmonth + ' ' + xday + ', ' + xyear);
    });

  }, [server_id, staff_id])

  return (
    <>
      {loading ? (
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
      ) : (
        <>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-4 col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className='container-fluid text-right'>
                        <NavLink to={`/Architecture/Servers/${server_id}/Staff/${staff_id}/Courses`}>
                          <i className={`fas fa-graduation-cap ml-5 p-cursor`}></i>
                        </NavLink>
                        <NavLink to={`/Architecture/Servers/${server_id}/Staff/${staff_id}/Edit`}>
                          <i className={`fas fa-pen ml-5 p-cursor`}></i>
                        </NavLink>
                        <i className={`fa fa-circle ${formData?.activeNow ? 'fg-green' : ''} ml-5`}></i>
                      </div>
                      <div className='container-fluid img-container text-center mb-2'>
                        <img src={formData?.profilePicture} />
                      </div>
                      <div className='container-fluid text-center'>
                        <h4 className='f-poppins fg-theme mb-2'>{formData.full_name}</h4>
                        <p className='f-poppins mb-2'><i className={`fa fa-phone-alt fg-theme`}></i> &nbsp; {formData.phone_number}</p>
                        <p className='f-poppins mb-2'><i className={`fa fa-envelope fg-theme`}></i> &nbsp; {formData.email}</p>
                        <p className='f-poppins mb-2'><i className={`fa fa-calendar fg-theme`}></i> &nbsp; {date_of_birth}</p>
                        <p className='f-poppins mb-2'><i className={`fa fa-map-marker fg-theme`}></i> &nbsp; {formData.street} {formData.town} {formData.county} {formData.country} {formData.postalCode}</p>
                      </div>
                      <div className='container-fluid text-center'>
                        <div className='card-body p-0' style={{ overflow: 'hidden', borderRadius: '20px' }}>
                          {/* <Iframe src={`https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3686.1061568683367!2d${formData?.active_location ? formData?.active_location[1] : 0}!3d${formData?.active_location ? formData?.active_location[0] : 0}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjLCsDMwJzAwLjciTiA4OMKwMjEnMjcuNSJF!5e0!3m2!1sen!2sin!4v1690974077802!5m2!1sen!2sin`} width="100%" height="480"></Iframe> */}
                          <Iframe src={`https://maps.google.com/maps?q=${formData?.active_location ? formData?.active_location[0] : 0},${formData?.active_location ? formData?.active_location[1] : 0}&output=embed`} width="100%" height="480"></Iframe>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>

            </div>
          </div>
        </>
      )}
    </>
  )
}

