import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import DatePicker from "react-datepicker";

import { TimePicker } from 'react-ios-time-picker';

import "react-datepicker/dist/react-datepicker.css";

import {
    create, update, retrieve
} from "./actions/arcServers";

export default function ArchitectureServers_add(props) {
    const dispatch = useDispatch();
    const InitialData = {
        server_id: '',
        server_name: '',
        company_name: '',
        company_email: '',
        phone_number: '',
        company_address: '',
        cqc_provider_id: '',
        cqc_location_id: '',
        opening_hours: '',
        website: '',
        is_architecture: true,
        is_sjf: false,
        is_elearning: false,
    };
    const InitialErrData = {
        res: 1,
        key: '',
        msg: ''
    };
    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    const [oldProp, setOldProp] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [formData, setFormData] = useState((props && props.is_edit && props.props && props.props.server_id) ? props.record_data : InitialData);
    const [errData, setErrData] = useState(InitialErrData);

    useEffect(() => {
        // console.log("isEdit", props.is_edit, formData, props)
        // console.log("oldProp", oldProp, "props", props)
        // console.log("props", props)
        if (props && props.tempAppPortal && props.tempAppPortal == 'elearning') {
            setFormData({
                server_id: (props.tempData.code?props.tempData.code:''),
                server_name: (props.tempData.hostname?props.tempData.hostname:''),
                company_name: (props.tempData.name?props.tempData.name:''),
                company_email: '',
                phone_number: '',
                company_address:
                (props.tempData.address?props.tempData.address:'') + ', ' +
                (props.tempData.city?props.tempData.city:'') + ', ' +
                (props.tempData.country?props.tempData.country:'') + ', ' +
                (props.tempData.postcode?props.tempData.postcode:''),
                cqc_provider_id: '',
                cqc_location_id: '',
                opening_hours: '',
                website: (props.tempData.hostname?props.tempData.hostname:''),
                is_architecture: true,
                is_sjf: false,
                is_elearning: false,
            })
        } else if (props && props.is_edit && props.props && props.props.server_id) {
            // console.log("Test 1")
            setCreateModalVisibility(true);
            setFormData({
                server_id: props.props.server_id,
                server_name: props.props.record_data.name,
                is_architecture: true,
                is_sjf: false,
                is_elearning: false,
            })
        } else {
            if (props.tempData) {
                // console.log(props.tempData)
                setFormData({
                    server_id: '',
                    server_name: (props.tempData.website?props.tempData.website:''),
                    company_name: (props.tempData.name?props.tempData.name:''),
                    company_email: '',
                    phone_number: (props.tempData.mainPhoneNumber?props.tempData.mainPhoneNumber:''),
                    company_address:
                    (props.tempData.companiesHouseNumber?props.tempData.companiesHouseNumber:'') + ', ' +
                    (props.tempData.postalAddressLine1?props.tempData.postalAddressLine1:'') + ', ' +
                    (props.tempData.postalAddressLine2?props.tempData.postalAddressLine2:'') + ', ' +
                    (props.tempData.postalAddressTownCity?props.tempData.postalAddressTownCity:'') + ', ' +
                    (props.tempData.postalCode?props.tempData.postalCode:''),
                    cqc_provider_id: (props.tempData.providerId?props.tempData.providerId:''),
                    cqc_location_id: (props.tempData.locationIds?props.tempData.locationIds:[]).join(','),
                    opening_hours: '',
                    website: (props.tempData.website?props.tempData.website:''),
                    is_architecture: true,
                    is_sjf: false,
                    is_elearning: false,
                })
            } else {
                setFormData(InitialData)
            }
            setCreateModalVisibility(false);
        }
    }, [oldProp, props]);

    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
        // if (props.callingExternal)
        props.updateParentState(false)
    }



    // const [file, setFile] = useState()

    // function handleFile(event) {
    //   setFile(event.target.files[0])

    //   let formData2 = new FormData();
    //   formData2.append('file', event.target.files[0]);
    //   formData2.append('fileName', event.target.files[0]['name']);
    //   setFormData({ ...formData, ['company_logo']: event.target.files[0] })
    // }
    let handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }



    let isNumeric = (value) => {
        return /^-?\d+$/.test(value);
    }

    let isAlphaNumeric = (value) => {
        return /^[a-zA-Z0-9 ]*$/.test(value);
    }

    let isText = (value) => {
        return /^[a-zA-Z ]*$/.test(value);
    }

    let isEmail = (value) => {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
    }

    let validationHandler = () => {
        let response = { res: 1, msg: 'Validated.' };
        if (formData?.server_name === undefined || formData?.server_name === '' || formData?.server_name === '')
            response = { res: 0, key: 'server_name', msg: 'Server name is required.' };
        return response;
    }

    let handleSave = () => {
        let validation_response = validationHandler();
        if (validation_response.res === 0) {
            setErrData(validation_response)
        } else {
            console.log(formData)
            if (formData?.server_id == undefined || formData?.server_id == '' || formData?.server_id.length == 0)
                dispatch(create(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        setOldProp(props);
                        alert(response.data.msg);
                        props.updateParentState(false)
                        setCreateModalVisibility(false);
                        let data = {
                            limit: 10,
                            last_id: '',
                            first_id: '',
                        };
                        dispatch(retrieve(data)).then(() => { });
                    }
                });
            else
                dispatch(update(formData)).then((response) => {
                    if (response.data.key)
                        setErrData(response.data)
                    else {
                        setOldProp(props);
                        alert(response.data.msg);
                        props.updateParentState(false)
                        setCreateModalVisibility(false);
                        let data = {
                            limit: 10,
                            last_id: '',
                            first_id: '',
                        };
                        dispatch(retrieve(data)).then(() => { });
                    }
                });
        }
    }
    // console.log("props", props.props, (props && props.props && props.props.server_id)?1:0);

    return (
        <>
            <div className="card card-default" role="document">
                <div className="card-body">
                    <div className="card-header">
                        <h4 className="card-title">Add Server</h4>
                        <button className="btn-close" type="button" aria-label="Close" data-bs-dismiss="modal" href="javascript:void(0)" onClick={handleCreateModalVisibility}></button>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12 col-lg-6 col-sm-12">
                                <div className='form-group'>
                                    <input type="hidden" name="csrf_token" value="" />
                                </div>
                                <div className='form-group'>
                                    <label className="form-label">Company Name</label>
                                    <input className="form-control" type="text" required="" autofocus="" name="company_name" onChange={handleChange} value={formData.company_name} placeholder="ex: Recruit-Tech" />
                                    {(errData.res == 0 && errData.key == 'company_name') ?
                                        <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                        : ''}
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-lg-6 col-sm-6">
                                        <div className='form-group'>
                                            <label className="form-label">Company Email</label>
                                            <input className="form-control" type="email" name="company_email" onChange={handleChange} value={formData.company_email} placeholder="jack@jcwhite.group" required="" />
                                            {(errData.res == 0 && errData.key == 'company_email') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-6">
                                        <div className='form-group'>
                                            <label className="form-label">Company Phone Number</label>
                                            <input className="form-control" type="tel" name="phone_number" onChange={handleChange} value={formData.phone_number} placeholder="+1 0000000000" required="" />
                                            {(errData.res == 0 && errData.key == 'phone_number') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label className="form-label">Company Server Name</label>
                                    <input className="form-control" type="text" name="server_name" onChange={handleChange} value={formData.server_name} placeholder="architecture.care" required="" />
                                    {(errData.res == 0 && errData.key == 'server_name') ?
                                        <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                        : ''}
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-lg-6 col-sm-6">
                                        <div className='form-group'>
                                            <label className="form-label">CQC Provider ID</label>
                                            <input className="form-control" type="text" name="cqc_provider_id" onChange={handleChange} value={formData.cqc_provider_id} placeholder="" />
                                            {(errData.res == 0 && errData.key == 'cqc_provider_id') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-6">
                                        <div className='form-group'>
                                            <label className="form-label">CQC Location ID</label>
                                            <input className="form-control" type="text" name="cqc_location_id" onChange={handleChange} value={formData.cqc_location_id} placeholder="" />
                                            {(errData.res == 0 && errData.key == 'cqc_location_id') ?
                                                <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label className="form-label">Company Full Address</label>
                                    <textarea className="form-control" type="text" name="company_address" onChange={handleChange} value={formData.company_address} placeholder="" rows="3"></textarea>
                                    {(errData.res == 0 && errData.key == 'company_address') ?
                                        <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                        : ''}
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6 col-sm-12">
                                {/* <label className="form-label">Company Logo</label>
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon5">image</span>
                                    <input type="file" className="form-control" onChange={handleFile} placeholder="" name="company_logo" />
                                    {(errData.res == 0 && errData.key == 'company_logo') ?
                                        <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                    : ''}
                                </div> */}
                                {/* <img id="company_logo_preview" className="d-none" src="#" alt="logo" width="200" height="200" /> */}
                                <div className='form-group'>
                                    <label className="form-label">Website</label>
                                    <input className="form-control" type="url" name="website" onChange={handleChange} value={formData.website} placeholder="" />
                                    {(errData.res == 0 && errData.key == 'website') ?
                                        <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                        : ''}
                                </div>
                                <div className='form-group'>
                                    <label className="form-label">Opening Hours</label>
                                    <input className="form-control" type="text" name="opening_hours" onChange={handleChange} value={formData.opening_hours} placeholder="10:00 am - 6:00 pm" />
                                    {(errData.res == 0 && errData.key == 'opening_hours') ?
                                        <small className='err-msg'><i className='fas fa-exclamation-triangle'></i> {errData.msg}</small>
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-light" type="button" data-bs-dismiss="modal" href="javascript:void(0)" onClick={handleCreateModalVisibility}>Close</button>
                        <button className="btn btn-primary" type="button" onClick={handleSave}>Save</button>
                    </div>
                </div>
            </div>
        </>
    )
}
