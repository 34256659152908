import React, { useState } from 'react'
import { useParams } from 'react-router-dom';

export default function VisitFilter({post, FilterFor, setFilterFor, Employee, ServiceUser, showModalAndTab}) {
  const { server_id, admin_id } = useParams();
    const FilterEmployeeFunc = () => {
        setFilterFor('STAFF');
        //   $('#ServiceUserOptionBar').hide();
        //   $('#EmployeeOptionBar').show();
        //   $('#manageForUserType').val('STAFF');
        //   $(this).addClass('active');
        //   $('#ShowServiceUserSectionsBTN').removeClass('active');
        //   $('.ShowEmployeeSections').show();
        //   $('.ShowServiceUserSections').hide();
        //   $('#EmployeeBTNSections').show();
        //   $('#ServiceUserBTNSections').hide();
        //   $('#viewSchedulePanel').hide();
        //   $('#CalendarPanel').hide();
        //   $('#service_user_filter').val('');
        //   $('#employee_filter').val('');
    }

    const FilterServiceUserFunc = () => {
        setFilterFor('USER');
        // $('#ServiceUserOptionBar').show();
        //   $('#EmployeeOptionBar').hide();
        //   $('#manageForUserType').val('USER');
        //   $(this).addClass('active');
        //   $('#ShowEmployeeSectionsBTN').removeClass('active');
        //   $('.ShowServiceUserSections').show();
        //   $('.ShowEmployeeSections').hide();
        //   $('#ServiceUserBTNSections').show();
        //   $('#EmployeeBTNSections').hide();
        //   $('#viewSchedulePanel').hide();
        //   $('#CalendarPanel').hide();
        //   $('#service_user_filter').val('');
        //   $('#employee_filter').val('');
    }


    return (
        <div className="col-md-3">
            <div className="row">
                {/* <div className="container-fluid"> */}
                    <div className="container-fluid card-body mt-3">
                        <h4>Server : <span id="server">{server_id}</span></h4>
                        <br />
                        <h4>Admin : <span id="admin">{admin_id}</span></h4>
                    </div>
                    <div className="container-fluid card-body mt-3">
                        <label>Check schedules for :</label><br /><br />
                        <input type="hidden" id="manageForUserType" value="USER" />
                        <div className='d-flex justify-space-between'>
                            <button id="ShowEmployeeSectionsBTN" className={`fc-button fc-button-primary ${FilterFor == 'STAFF' ? 'active' : ''}`}
                                onClick={FilterEmployeeFunc}>Employee</button>
                            <button id="ShowServiceUserSectionsBTN" className={`ml-2 fc-button fc-button-primary ${FilterFor == 'USER' ? 'active' : ''}`}
                                onClick={FilterServiceUserFunc}>Service User</button>
                        </div>
                    </div>
                    {
                        FilterFor == 'USER' ?
                            <div className="container-fluid card-body mt-3 ShowServiceUserSections">
                                <h4>Filters : </h4>
                                <div className="form-group">
                                    <label>Filter Schedules for Service User</label>
                                    <select className="form-select" id="service_user_filter">
                                        <option value=""> - Select - </option>
                                        {
                                            ServiceUser && ServiceUser?.length > 0 ?
                                            ServiceUser?.map((val, index) => (
                                                <option value={`${val?.username}`}> {`${val?.full_name} (${val?.email})`} </option>
                                            ))
                                            : ''
                                        }
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Schedule Category</label>
                                    <select className="form-select" id="visit_category_for_service_user">
                                        <option value=""> - Select - </option>
                                        <option value="CONTINUOUS"> Continuous </option>
                                        <option value="DAYCARES"> Day Cares </option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Schedule Status</label>
                                    <select className="form-select" id="visit_status_for_service_user">
                                        <option value=""> - Select - </option>
                                        <option value="NEW"> New </option>
                                        <option value="ASSIGNED"> Assigned </option>
                                    </select>
                                </div>
                            </div>
                            : FilterFor == 'STAFF' ?
                                <div className="container-fluid card-body mt-3 ShowEmployeeSections">
                                    <h4>Filters : </h4>
                                    <div className="form-group">
                                        <label>Filter Schedules for Employee</label>
                                        <select className="form-select" id="employee_filter">
                                        <option value=""> - Select - </option>
                                        {
                                            Employee && Employee?.length > 0 ?
                                            Employee?.map((val, index) => (
                                                <option value={`${val?.username}`}> {`${val?.full_name} (${val?.email})`} </option>
                                            ))
                                            : ''
                                        }
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Schedule Type</label>
                                        <select className="form-select" id="schedule_type">
                                            <option value=""> - Select - </option>
                                            <option value="VISIT"> Visit </option>
                                            <option value="MEETING"> Meeting </option>
                                            <option value="TRAINING"> Training </option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Schedule Status</label>
                                        <select className="form-select" id="visit_status_for_employee">
                                            <option value=""> - Select - </option>
                                            <option value="ASSIGNED"> Assigned </option>
                                            <option value="COMPLETED"> Complete </option>
                                        </select>
                                    </div>
                                </div>
                                : ''
                    }
                {/* </div> */}
            </div>
            <div className="row">
                {/* <div className="container-fluid"> */}
                    <div className="container-fluid card-body mt-3" id="viewSchedulePanel">
                        <input type="hidden" id="_service_user_visit_details_all_day" />
                        <input type="hidden" id="_service_user_visit_details_schedule_id" />
                        <input type="hidden" id="_service_user_visit_details_server_id" />
                        <input type="hidden" id="_service_user_visit_details_admin_id" />
                        <input type="hidden" id="_service_user_visit_details_service_user_id" />
                        <input type="hidden" id="_service_user_visit_details_activity_id" />
                        <input type="hidden" id="_service_user_visit_details_employee_id" />
                        <input type="hidden" id="_service_user_visit_details_is_notified" />
                        <input type="hidden" id="_service_user_visit_details_visit_category" />
                        <input type="hidden" id="_service_user_visit_details_start_date" />
                        <input type="hidden" id="_service_user_visit_details_start_time" />
                        <input type="hidden" id="_service_user_visit_details_end_date" />
                        <input type="hidden" id="_service_user_visit_details_end_time" />
                        <div className="row">
                            <div className="container-fluid text-right">
                                <div className="d-flex justify-content-between">
                                    <h4>Schedule Details : </h4>
                                    <div className="">
                                        <i className="fa fa-toggle-on" id="activeStatusForSchedule"></i>
                                        <i className="fa fa-toggle-off" id="inactiveStatusForSchedule"></i>
                                        {/* <!-- <button type="button" className="fc-button fc-button-primary" id="scheduleEdit"><i className="fa fa-pencil-alt"></i></button> --> */}
                                        <button type="button" className="fc-button fc-button-primary" id="cloneSchedule">
                                            <i className="fa fa-clone"></i>
                                        </button>
                                        <button type="button" className="fc-button fc-button-primary" id="scheduleTrash">
                                            <i className="fa fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <p>Start : <span id="view_start_text"></span></p>
                                <p>End : <span id="view_end_text"></span></p>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <p>Category : <span id="view_visit_category_text"></span></p>
                                    </div>
                                    <div className="col">
                                        <p>All Day : <span id="view_allDay_text"></span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <p>Is Notified : <span id="view_is_notified_text"></span></p>
                                    </div>
                                    <div className="col">
                                        <p>Status : <span id="view_visit_status_text"></span></p>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <p id="assignEmployeeContainer">
                                    <button type="button" className="fc-button fc-button-primary ml-2" id="assignEmployeeBTN" onClick={() => showModalAndTab('assignEmployeeModal', '')}>Assign Employee</button>
                                    <button type="button" className="fc-button fc-button-primary ml-2" id="assignActivityBTN" onClick={() => showModalAndTab('assignActivityPopup', '')}>Assign Activity</button>
                                </p>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
            </div>
        </div>
    )
}
