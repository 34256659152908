import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { NavLink, useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';

import postAPI from './service/api';


import 'sweetalert/dist/sweetalert.css';

import SweetAlert from 'sweetalert-react';

import ArchitectureServerServiceUsersAddModal from './architectureServerServiceUsers_add';
import ArchitectureServerServiceUsersEmailModal from './architectureServerServiceUsers_email';
import ArchitectureServerServiceUsersPhoneModal from './architectureServerServiceUsers_phone';

import Popup from '../../../components/popup';


import Iframe from 'react-iframe';
import { Map, Marker } from '@googlemaps/react-wrapper'

import {
    retrieve, trash, changeStatus
} from './actions/arcServerServiceUsers';


const MyMarker = () => {
    return <Marker position={{ lat: 37.7749, lng: -122.4194 }} />
}

export default function ArchitectureServerServiceUsers() {
    const { server_id } = useParams();
    const MapData = {
        latitude: 0,
        longitude: 0,
    }

    const [map_data, setMapData] = useState(MapData);
    const server_service_user_data = useSelector(state => state.ARCServerServiceUsersReducer, shallowEqual);
    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    const [createModalVisibilityMap, setCreateModalVisibilityMap] = useState(false);
    // For Pagination Part
    const [firstIdHandlerForPagination, setFirstIdHandlerForPagination] = useState([]);
    const [lastIdHandlerForPagination, setLastIdHandlerForPagination] = useState([]);
    // For Pagination Part
    const [createModalVisibilityPhone, setCreateModalVisibilityPhone] = useState(false);
    const [createModalVisibilityEmail, setCreateModalVisibilityEmail] = useState(false);

    const [singleValueEmail, setSingleValueEmail] = useState({});
    const [singleValuePhone, setSingleValuePhone] = useState({});

    let handleCreateModalVisibilityPhone = (element) => {
        // console.log(element)
        setSingleValuePhone(element);
        setCreateModalVisibilityPhone(!createModalVisibilityPhone);
    }

    let updateParentStatePhone = () => {
        setSingleValuePhone({});
        setCreateModalVisibilityPhone(!createModalVisibilityPhone);
    }

    let handleCreateModalVisibilityEmail = (element) => {
        // console.log(element)
        setSingleValueEmail(element);
        setCreateModalVisibilityEmail(!createModalVisibilityEmail);
    }

    let updateParentStateEmail = () => {
        setSingleValueEmail({});
        setCreateModalVisibilityEmail(!createModalVisibilityEmail);
    }

    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
    }

    let updateParentState = () => {
        setCreateModalVisibility(!createModalVisibility);
    }

    let updateParentStateMap = () => {
        setCreateModalVisibilityMap(!createModalVisibilityMap);
    }
    const initial_single_data = {
        id: '',
        record_data: {}
    };
    const [single_data, setSingleData] = useState(initial_single_data);
    const [record_length, setRecordLength] = useState(10);
    const [page, setPage] = useState(0);
    const [showTrashAlert, setShowTrashAlert] = useState({ show: false });
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    const fetchServiceUsers = recordLength => {
        setLoading(true);
        let data = {
            server_id: server_id,
            limit: parseInt(recordLength),
            last_id: '',
            first_id: '',
        };
        dispatch(retrieve(data)).then((response) => {
            setLoading(false);
            // For Pagination Part
            let firstIDArr = firstIdHandlerForPagination;
            let lastIDArr = lastIdHandlerForPagination;
            firstIDArr.push(response && response.first_id);
            lastIDArr.push(response && response.last_id);
            setFirstIdHandlerForPagination(firstIDArr);
            setLastIdHandlerForPagination(lastIDArr);
            // For Pagination Part
        });
    }
    useEffect(() => {
        fetchServiceUsers(record_length);
    }, []);

    let handleChange = (e) => {
        if (e.target.name == 'record_length') {
            setRecordLength(e.target.value);
            fetchServiceUsers(e.target.value);
        }
    }

    let handlePrev = () => {
        setLoading(true);
        let data = {
            server_id: server_id,
            limit: record_length,
            last_id: '',
            first_id: firstIdHandlerForPagination[firstIdHandlerForPagination.length - 2],
        };
        dispatch(retrieve(data)).then(() => {
            setPage(page > 0 ? page - 1 : page)
            setLoading(false)
            // For Pagination Part
            let firstIDArr = firstIdHandlerForPagination.slice(0, firstIdHandlerForPagination.length - 1);
            let lastIDArr = lastIdHandlerForPagination.slice(0, lastIdHandlerForPagination.length - 1);
            setFirstIdHandlerForPagination(firstIDArr);
            setLastIdHandlerForPagination(lastIDArr);
            // For Pagination Part
        });
    }
    let handleNext = () => {
        setLoading(true);
        setPage((
            page <= (
                parseInt((
                    server_service_user_data &&
                        server_service_user_data.data &&
                        server_service_user_data.data.total ? server_service_user_data.data.total : 1
                ) / record_length) + 1
            ))
            ? page + 1
            : page
        );
        let data = {
            server_id: server_id,
            limit: record_length,
            last_id: server_service_user_data.data.last_id,
            first_id: '',
        };
        dispatch(retrieve(data)).then((response) => {
            // For Pagination Part
            let firstIDArr = firstIdHandlerForPagination;
            let lastIDArr = lastIdHandlerForPagination;
            firstIDArr.push(response && response.first_id);
            lastIDArr.push(response && response.last_id);
            setFirstIdHandlerForPagination(firstIDArr);
            setLastIdHandlerForPagination(lastIDArr);
            // For Pagination Part
            setLoading(false)
        });
    }







    let handleEdit = (record) => {
        updateParentState();
        setSingleData(
            {
                id: record.id,
                record_data: record
            }
        )
        // console.log(single_data)
    }

    let handleShowTrashAlert = (val) => {
        // console.log(val, val.uid)
        setShowTrashAlert({ val: val, show: !showTrashAlert.show });
    }

    let handleAlertResponse = () => {
        handleDelete();
    }

    let handleDelete = () => {
        console.log("showTrashAlert", showTrashAlert)
        setLoading(true);
        let data = {
            server_id: server_id,
            uid: showTrashAlert.val.uid,
            email: showTrashAlert.val.email,
            username: showTrashAlert.val.username,
        };
        dispatch(trash(data)).then(() => {
            setLoading(true);
            data = {
                server_id: server_id,
                limit: record_length,
                last_id: '',
                first_id: '',
            };
            dispatch(retrieve(data)).then(() => {
                setLoading(false);
                setTimeout(() => {
                    setShowTrashAlert({ show: !showTrashAlert.show });
                }, 1000);
            });
        });
    }

    let handleMapData = (value) => {
        // console.log(value.active_location)
        setMapData({
            latitude: value.active_location[0],
            longitude: value.active_location[1],
        })
        setCreateModalVisibilityMap(!createModalVisibilityMap);
        // console.log(value)
    }

    let toggleStatus = (single_record) => {
        // setLoading(true);
        let data = {
            server_id: server_id,
            uid: single_record.uid,
            username: single_record.username,
            status: !single_record.status,
        };
        dispatch(changeStatus(data)).then((response) => {
            console.log(response);
            data = {
                server_id: server_id,
                limit: record_length,
                last_id: '',
                first_id: '',
            };
            dispatch(retrieve(data)).then(() => {
                setLoading(false);
            });
        });
        console.log(single_record);
    }


    const toggleSuspend = async user => {
        try {
            const endpoint = !user?.is_suspended ? "admins/suspend_user" : "admins/remove_suspension";
            const filter = {
                username: user?.username,
                user_type: 'user',
                server_id: server_id
            };
            const response = await postAPI(endpoint, filter);
            console.log(response);
            if (response?.res) {
                window?.location?.reload();
                return response;
            } else {
                return { res: 0, error: response?.error };
            }
        } catch (e) {
            const errorMessage = e?.response?.data?.error || "Something went wrong";
            console.log("Exception Error:", errorMessage);
            return { res: 0, error: errorMessage }; // Return error to caller
        }
    }






    return (
        <>
            {loading ?
                <div>
                    <Placeholder.Paragraph rows={8} />
                    <Loader center content="loading" />
                </div>
                :
                <>
                    <div className="d-sm-flex justify-content-between align-items-center mb-4">
                        <h3 className="text-dark mb-0">Server Service Users</h3>
                        <a className="btn btn-primary btn-sm d-none d-sm-inline-block ml-15" role="button" href="javascript:void(0)" onClick={handleCreateModalVisibility}>
                            <i className="fas fa-plus fa-sm"></i>&nbsp;Add New Service User
                        </a>
                        <Popup visibility_status={createModalVisibility} updateParentState={updateParentState}><ArchitectureServerServiceUsersAddModal updateParentState={updateParentState} /></Popup>

                        <Popup visibility_status={createModalVisibilityEmail} updateParentStateEmail={updateParentStateEmail}><ArchitectureServerServiceUsersEmailModal singleValueEmail={singleValueEmail} updateParentStateEmail={updateParentStateEmail} /></Popup>
                        <Popup visibility_status={createModalVisibilityPhone} updateParentStatePhone={updateParentStatePhone}><ArchitectureServerServiceUsersPhoneModal singleValuePhone={singleValuePhone} updateParentStatePhone={updateParentStatePhone} /></Popup>

                    </div>
                    <Popup visibility_status={createModalVisibilityMap} updateParentStateMap={updateParentStateMap}>
                        <div className='container-fluid mt-2 mb-2 text-right'>
                            <button className='btn btn-primary' onClick={updateParentStateMap}><i className='fa fa-times'></i></button>
                        </div>
                        <div className='container-fluid'>
                            {/* <Iframe src={`https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3686.1061568683367!2d${map_data.longitude}!3d${map_data.latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjLCsDMwJzAwLjciTiA4OMKwMjEnMjcuNSJF!5e0!3m2!1sen!2sin!4v1690974077802!5m2!1sen!2sin`} width="100%" height="480"></Iframe> */}
                            <Iframe src={`https://maps.google.com/maps?q=${map_data.latitude},${map_data.longitude}&output=embed`} width="100%" height="480"></Iframe>
                        </div>
                    </Popup>
                    <div className="card shadow hidden-xs">
                        {/* <div className="card-header py-3">
                    <p className="text-primary m-0 fw-bold">Server List</p>
                </div> */}
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6 text-nowrap">
                                    <div id="dataTable_length" className="dataTables_length" aria-controls="dataTable">
                                        <label className="form-label">Show&nbsp;
                                            <select className="d-inline-block form-select form-select-sm" name='record_length' defaultValue={record_length} onChange={handleChange}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                            <div className="text-md-end dataTables_filter" id="dataTable_filter"><label className="form-label"><input type="search" className="form-control form-control-sm" aria-controls="dataTable" placeholder="Keyword Search" /></label></div>
                        </div> */}
                            </div>
                            <div className="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                                <table className="table my-0" id="dataTable">
                                    <thead>
                                        <tr>
                                            <th>Username</th>
                                            <th>Type</th>
                                            <th>Full Name</th>
                                            <th>Email Address</th>
                                            <th>Phone Number</th>
                                            <th>Date of Birth</th>
                                            <th>Key Code</th>
                                            <th>Gender</th>
                                            <th>Suspend</th>
                                            <th>Status</th>
                                            <th style={{ width: "110px" }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            server_service_user_data &&
                                                server_service_user_data.data &&
                                                // server_service_user_data.data.length > 0 && 
                                                server_service_user_data.data.service_users &&
                                                server_service_user_data.data.service_users.length > 0 ?
                                                server_service_user_data.data.service_users.map((val, index) => {
                                                    return (
                                                        <tr>
                                                            <td><NavLink to={`/Architecture/Servers/${server_id}/ServiceUser/${val.username}/Details`}>{val.username}</NavLink></td>
                                                            <td>{val.type}</td>
                                                            <td>{val.full_name} <sup><small><i className={`fa fa-circle ${val.activeNow ? 'fg-green' : ''}`}></i></small></sup></td>
                                                            <td>{val.email}</td>
                                                            <td>{val.phone_number}</td>
                                                            <td>{val.dob}</td>
                                                            <td>{val.keycode}</td>
                                                            <td>{val.gender}</td>
                                                            {val.uid ?
                                                                <td><i onClick={() => toggleSuspend(val)} className={!val.is_suspended ? 'fa fa-toggle-off fg-red' : 'fa fa-toggle-on fg-green'}></i></td>
                                                                :
                                                                <td><i className={'fa fa-toggle-off'}></i></td>
                                                            }
                                                            {val.uid ?
                                                                <td><i onClick={() => toggleStatus(val)} className={!val.status ? 'fa fa-toggle-off fg-red' : 'fa fa-toggle-on fg-green'}></i></td>
                                                                :
                                                                <td><i className={'fa fa-toggle-off'}></i></td>
                                                            }
                                                            <td>
                                                                <button className='btn btn-light btn-sm mr-1' type='button' onClick={() => handleMapData(val)}><i className={'fa fa-map-marker'}></i></button>
                                                                <button className='btn btn-light btn-sm mr-1' type='button' onClick={() => handleCreateModalVisibilityEmail(val)}><i className={'fa fa-envelope'}></i></button>
                                                                <button className='btn btn-light btn-sm mr-1' type='button' onClick={() => handleCreateModalVisibilityPhone(val)}><i className={'fa fa-phone'}></i></button>
                                                                <NavLink to={`/Architecture/Servers/${server_id}/ServiceUser/${val.username}/Edit`}><button type='button' className='btn btn-light btn-sm mr-15'><i className="fas fa-edit"></i></button></NavLink>
                                                                {/* Pradeepto Da company r applicant er delete hide kore dao karon etay kichu complicated process ache same architecture er server. staff. user. admin */}
                                                                <button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleShowTrashAlert(val)}><i className="fas fa-trash fg-theme"></i></button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                : ''
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-md-6 align-self-center">
                                    <p id="dataTable_info" className="dataTables_info" role="status" aria-live="polite">Total Records {server_service_user_data && server_service_user_data.data && server_service_user_data.data.total ? server_service_user_data.data.total : 0}</p>
                                </div>
                                <div className="col-md-6">
                                    <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                        <ul className="pagination">
                                            {page ?
                                                <li className={page ? "page-item" : "page-item disabled"} onClick={handlePrev}><a className="page-link" aria-label="Previous" href="#"><span aria-hidden="true">«</span> Previous</a></li>
                                                : ''}
                                            {(page <= (parseInt((server_service_user_data && server_service_user_data.data && server_service_user_data.data.total ? server_service_user_data.data.total : 1) / record_length) - 1)) ?
                                                <li className={(page <= (parseInt((server_service_user_data && server_service_user_data.data && server_service_user_data.data.total ? server_service_user_data.data.total : 1) / record_length) - 1)) ? "page-item" : "page-item disabled"} onClick={handleNext}><a className="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span></a></li>
                                                : ''}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <SweetAlert
                                show={showTrashAlert.show}
                                title=""
                                text="Are you sure you want to permanently delete this record?"
                                onCancel={() => handleShowTrashAlert(false)}
                                showCancelButton
                                onConfirm={handleAlertResponse}
                            />
                        </div>
                    </div>

                    <div className="row shown-xs">
                        <div className="container-fluid text-nowrap">
                            <div id="dataTable_length" className="dataTables_length" aria-controls="dataTable">
                                <label className="form-label">Show&nbsp;
                                    <select className="d-inline-block form-select form-select-sm" name='record_length' defaultValue={record_length} onChange={handleChange}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                {
                                    server_service_user_data &&
                                        server_service_user_data.data &&
                                        // server_service_user_data.data.length > 0 && 
                                        server_service_user_data.data.service_users &&
                                        server_service_user_data.data.service_users.length > 0 ?
                                        server_service_user_data.data.service_users.map((val, index) => {
                                            return (
                                                <div className="col-md-6 mt-2">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <div className='align-right'>
                                                                <button className='btn btn-light btn-sm mr-1' type='button' onClick={() => handleMapData(val)}><i className={'fa fa-map-marker'}></i></button>
                                                                <button className='btn btn-light btn-sm mr-1' type='button' onClick={() => handleCreateModalVisibilityEmail(val)}><i className={'fa fa-envelope'}></i></button>
                                                                <button className='btn btn-light btn-sm mr-1' type='button' onClick={() => handleCreateModalVisibilityPhone(val)}><i className={'fa fa-phone'}></i></button>
                                                                <NavLink to={`/Architecture/Servers/${server_id}/ServiceUser/${val.username}/Edit`}><button type='button' className='btn btn-light btn-sm mr-15'><i className="fas fa-edit"></i></button></NavLink>
                                                                {/* Pradeepto Da company r applicant er delete hide kore dao karon etay kichu complicated process ache same architecture er server. staff. user. admin */}
                                                                <button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleShowTrashAlert(val)}><i className="fas fa-trash fg-theme"></i></button>
                                                            </div>
                                                        </div>
                                                        <div className="card-body shown-xs">
                                                            <h5><NavLink to={`/Architecture/Servers/${server_id}/ServiceUser/${val.username}/Details`}>{val.username}</NavLink></h5>
                                                            <br />
                                                            <p><b>Type</b>: <br/>{val.type}</p>
                                                            <p><b>Full Name</b>: <br/>{val.full_name} <sup><small><i className={`fa fa-circle ${val.activeNow ? 'fg-green' : ''}`}></i></small></sup></p>
                                                            <p><b>Email Address</b>: <br/>{val.email}</p>
                                                            <p><b>Phone Number</b>: <br/>{val.phone_number}</p>
                                                            <p><b>Date of Birth</b>: <br/>{val.dob}</p>
                                                            <p><b>Key Code</b>: <br/>{val.keycode}</p>
                                                            <p><b>Gender</b>: <br/>{val.gender}</p>
                                                            <p>
                                                                <b>Status</b> {val.uid ?
                                                                    <td><i onClick={() => toggleStatus(val)} className={!val.status ? 'fa fa-toggle-off fg-red' : 'fa fa-toggle-on fg-green'}></i></td>
                                                                    :
                                                                    <td><i className={'fa fa-toggle-off'}></i></td>
                                                                }
                                                            </p>
                                                            {val.uid ?
                                                                <p>Suspend : <i onClick={() => toggleSuspend(val)} className={!val.is_suspended ? 'fa fa-toggle-off fg-red' : 'fa fa-toggle-on fg-green'}></i></p>
                                                                :
                                                                <p>Suspend : <i className={'fa fa-toggle-off'}></i></p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : ''
                                }
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 align-self-center">
                                    <br />
                                    <p id="dataTable_info" className="dataTables_info text-center" role="status" aria-live="polite">Total Records {server_service_user_data && server_service_user_data.data && server_service_user_data.data.total ? server_service_user_data.data.total : 0}</p>
                                </div>
                                <div className="col-md-12 text-right">
                                    <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                        <ul className="pagination">
                                            {/* {page ?
                                                <li className={page ? "page-item" : "page-item disabled"} onClick={handlePrev}><a className="page-link" aria-label="Previous" href="#"><span aria-hidden="true">«</span> Previous</a></li>
                                                : ''}
                                            {(page <= (parseInt((server_data && server_data.data && server_data.data.total ? server_data.data.total : 1) / record_length) - 1)) ?
                                                <li className={(page <= (parseInt((server_data && server_data.data && server_data.data.total ? server_data.data.total : 1) / record_length) - 1)) ? "page-item align-right" : "page-item align-right disabled"} onClick={handleNext}><a className="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span></a></li>
                                                : ''} */}
                                            {page ?
                                                <li className={page ? "page-item" : "page-item disabled"} onClick={handlePrev}><a className="page-link" aria-label="Previous" href="#"><span aria-hidden="true">«</span> Previous</a></li>
                                                : ''}
                                            {(page <= (parseInt((server_service_user_data && server_service_user_data.data && server_service_user_data.data.total ? server_service_user_data.data.total : 1) / record_length) - 1)) ?
                                                <li className={(page <= (parseInt((server_service_user_data && server_service_user_data.data && server_service_user_data.data.total ? server_service_user_data.data.total : 1) / record_length) - 1)) ? "page-item align-right" : "page-item align-right disabled"} onClick={handleNext}><a className="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span></a></li>
                                                : ''}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
