import React, { useState } from 'react'
import { useParams } from 'react-router-dom';

import EmployeeListTabs from './popups/EmployeeListTabs';
import ServiceUserListTabs from './popups/ServiceUserListTabs';

export default function VisitAction({ post, FilterFor, setFilterFor, showModalAndTab, ModalId, setModalId, TabId, setTabId }) {
    const { server_id, admin_id } = useParams();

    return (
        <div className="col-md-3">
            <div className="row">
                <div className="container-fluid card-body mt-3">
                    {
                        FilterFor == 'USER' ?
                            <div className="d-flex justify-space-between">
                                <button type="button" className="fc-button fc-button-primary btn-sm" onClick={() => showModalAndTab('ServiceUserListTabs', 'care-days-tab')}>Care Days</button>
                                <button type="button" className="fc-button fc-button-primary btn-sm" onClick={() => { ($('#service_user_filter').val().length > 0) ? showModalAndTab('ServiceUserListTabs', 'activities-tab') : alert('Choose Service User First.') }}>Activities</button>
                                <button type="button" className="fc-button fc-button-primary btn-sm" onClick={() => { ($('#service_user_filter').val().length > 0) ? showModalAndTab('ServiceUserListTabs', 'medications-tab') : alert('Choose Service User First.') }}>Medications</button>
                                <button type="button" className="fc-button fc-button-primary btn-sm" onClick={() => { ($('#service_user_filter').val().length > 0) ? showModalAndTab('ServiceUserListTabs', 'medicine-stocks-tab') : alert('Choose Service User First.') }}>Medicine Stocks</button>
                            </div>
                            : FilterFor == 'STAFF' ?
                                <div className="d-flex justify-space-between">
                                    <button type="button" className="fc-button fc-button-primary btn-sm" onClick={() => showModalAndTab('EmployeeListTabs', 'work-days-tab')}>Work Days</button>
                                    <button type="button" className="fc-button fc-button-primary btn-sm" onClick={() => showModalAndTab('EmployeeListTabs', 'leaves-tab')}>Leaves</button>
                                </div>
                                : ''
                    }
                </div>
            </div>
            <EmployeeListTabs ModalId={ModalId} TabId={TabId} showModalAndTab={showModalAndTab} />
            <ServiceUserListTabs ModalId={ModalId} TabId={TabId} showModalAndTab={showModalAndTab} />
        </div>
    )
}
