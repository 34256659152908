import React, { useState } from 'react'

export default function MobileSidebar({ val, setShowSubMenu, setSubMenu }) {
    return (
        <>
            <div className='col-xs-4 col-sm-4 mt-2 mb-2'>
                <div className='card' onClick={() => { if (val?.type == 'accordian') { setShowSubMenu(true); setSubMenu(val?.child); } else { window.location.href = val?.link } }}>
                    <div className='card-body'>
                        {val?.logo ?
                            <div className='row text-center'>
                                <div className='container-fluid mt-2'>
                                    <img src={val?.logo} width={50}></img>
                                </div>
                                <div className='container-fluid mt-4'>
                                    <p>{val?.menu_name}</p>
                                </div>
                            </div>
                            :
                            <div className='row'>
                                <div className='container-fluid d-block '>
                                    <div className='row d-flex flex-direction-row justify-space-between'>
                                        <div className='w-auto'>
                                            <p>{val?.menu_name}</p>
                                        </div>
                                        <div className='w-auto'>
                                            <p><i className='fa fa-angle-right' /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
