import http from "../../../../http-axios.function";


const getConfig = async () => {
    return {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json;charset=UTF-8",
        }
    };
};

export const postAPI = async (apiName, data) => {
    const response = await http.post(`${apiName}`, { ...data }, await getConfig());
    return response?.data?.data;
};


export default postAPI;