import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';

import { retrieve } from './actions/AdminPoliciesProcedures'
import CalenderView from './components/CalendarView';

export default function AdminPoliciesProcedures() {
  const { server_id, admin_id } = useParams();
  const [PoliciesProcedures, setPoliciesProcedures] = useState([]);
  const [UserType, setUserType] = useState('staff');
  const [loading, setLoading] = useState(true);


  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    fetchPoliciesProcedures(server_id, admin_id, UserType)
  }, [server_id, admin_id, UserType]);

  let fetchPoliciesProcedures = (server_id, admin_id, UserType) => {
    let data = {
      server_id: server_id ? server_id : '',
      admin_id: admin_id ? admin_id : '',
      user_type: UserType,
      limit: 1,
      last_id: '',
      first_id: '',
    };
    dispatch(retrieve(data)).then((response) => {
      setLoading(false);
      setPoliciesProcedures(response?.data);
    });
  }


  let formatDateTime = (date, time, format) => {
    if (date && date.length > 0 && time && time.length > 0 && format == 'd/m/y H:i a') {
      // console.log(date, time)
      let tempDay = date.split('/')[0]
      let tempMonth = date.split('/')[1]
      let tempYear = date.split('/')[2]
      let time_ = time.split(' ')[0];
      let hour = time_.split(':')[0];
      hour = parseInt(time_.split(':')[0]);
      let minute = time_.split(':')[1];
      if (time.split(' ')[1] == 'PM') {
        return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? hour < 10 ? hour + 12 : hour + 12 : hour) + ':' + minute + ':00').toISOString();
      } else {
        return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? '0' + hour : hour) + ':' + minute + ':00').toISOString();
      }
    } else {
      return new Date().toISOString();
    }
  }
  return (
    <>
      {loading ?
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
        :
        <>
          <div className='row'>
            <div className='col-md-9 mb-3'>
              <h4 className='fg-theme mb-2 mt-2'>Policies &amp; Procedures</h4>
            </div>
            <div className='col-md-3'>
              <select className='form-select' name='user_type' id='user_type' value={UserType} onChange={(e) => { setUserType(e.target.value); fetchPoliciesProcedures(server_id, admin_id, e.target.value) }}>
                <option value={''}> - Select - </option>
                <option value={'staff'}> Employees </option>
                <option value={'user'}> Service Users </option>
              </select>
            </div>
          </div>
          <div className='row'>
            {
              PoliciesProcedures && PoliciesProcedures.length > 0 ?
                PoliciesProcedures.map((val, index) => {
                  return (
                    <div className='col-md-12 col-lg-4 col-sm-12 mt-1'>
                      <div className='card'>
                        <div className='card-body'>
                          {/* <p className='text-right'>{val.current?'Online':'Offline'}</p> */}
                          <h4 className='fg-theme mb-2 mt-2'>{val?.title ? val?.title : ''}</h4>
                          <p>{val?.description}</p>
                          {/* <p>Description : {val?.description}</p> */}
                          <p>Publish Date : {Date(val?.publishDate)}</p>
                          <p>Expiry Date : {Date(val?.expiryDate)}</p>
                          <p>Revoked : <i className={`fa fa-${val?.revoked?'toggle-on fg-green':'toggle-off fg-red'}`}></i></p>
                          <p>Deleted : <i className={`fa fa-${val?.isDeleted?'toggle-on fg-green':'toggle-off fg-red'}`}></i></p>
                          {/* <p>Total : {val?.total.join(",")}</p> */}
                          {/* <p>Accepted : {val?.accepted.join(",")}</p>
                          <p>Declined : {val?.declined.join(",")}</p> */}
                          
                          {/* <p>Publish Date : {formatDateTime(val?.publishDate)}</p>
                          <p>Expiry Date : {formatDateTime(val?.expiryDate)}</p> */}
                          {/* <p>Status : <i onClick={() => handleAccess(val?.device_id, !val?.active)} className={`fa fa-${val?.active ? 'toggle-on fg-green' : 'toggle-off fg-red'}`}></i></p> */}
                          {/* <p>Access : {val.access?'TRUE':'FALSE'}</p> */}
                        </div>
                      </div>
                    </div>
                  )
                })
                : ''
            }
          </div>
        </>
      }
    </>
  )
}
