import React, { useState } from 'react'
import { useParams } from 'react-router-dom';

export default function CreateNewEmployeeWorkDayModal({ post, Employee, ModalId, setModalId, TabId, setTabId, showModalAndTab }) {
    const { server_id, admin_id } = useParams();
    const [FilterPayload, setFilterPayload] = useState({});

    const handleChange = e => {
        const { name, value } = e?.target;
        setFilterPayload({
            ...FilterPayload,
            [name]: value
        })
    }

    const handleChecked = e => {
        const { name, checked, value } = e?.target;
        if (checked) {
            setFilterPayload({
                ...FilterPayload,
                [name]: value
            })
        } else {
            setFilterPayload({
                ...FilterPayload,
                [name]: ''
            })
        }
    }

    const convertTo12HourFormat = (time24) => {
        // Split the input time into hours and minutes
        let [hours, minutes] = time24.split(':').map(Number);

        // Determine AM or PM suffix
        const period = hours >= 12 ? 'PM' : 'AM';

        // Convert 24-hour format to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12;  // Handle case for 0 (midnight)

        // Format minutes as a two-digit string
        hours = hours < 10 ? `0${hours}` : hours;
        minutes = minutes < 10 ? `0${minutes}` : minutes;

        // Return the formatted time in 12-hour format
        return `${hours}:${minutes} ${period}`;
    }


    const convertTo24HourFormat = (time24) => {
        // Split the input time into hours and minutes
        let [hours, minutes, seconds] = time24.split(':').map(Number);

        // Determine AM or PM suffix
        const period = hours >= 12 ? 12 : 0;

        // Convert 24-hour format to 12-hour format
        // hours = hours % 12;
        hours = hours ? hours : 12;  // Handle case for 0 (midnight)

        hours = hours < 10 ? `0${hours}` : hours;

        // Format minutes as a two-digit string
        minutes = minutes < 10 ? `0${minutes}` : minutes;

        seconds = seconds < 10 ? `0${seconds}` : seconds;

        // Return the formatted time in 12-hour format
        return `${hours}:${minutes}:${seconds}`;
    }

    const saveEmployeeWorkDay = async () => {
        const endpoint = "employees/employee_work_days_for_schedule/create";
        const filter = {
            server_id: server_id,
            staff_id: FilterPayload?.staff_id,
            year: FilterPayload?.shift_start_date.split('-')[0],
            month: FilterPayload?.shift_start_date.split('-')[1],
            shift_start_date: FilterPayload?.shift_start_date,
            shift_start_time: convertTo12HourFormat(FilterPayload?.shift_start_time),
            shift_end_date: FilterPayload?.shift_end_date,
            shift_end_time: convertTo12HourFormat(FilterPayload?.shift_end_time),
            is_break_alloted: FilterPayload?.is_break_alloted,
            break_start_date: FilterPayload?.break_start_date,
            break_start_time: convertTo12HourFormat(FilterPayload?.break_start_time),
            break_end_date: FilterPayload?.break_end_date,
            break_end_time: convertTo12HourFormat(FilterPayload?.break_end_time),
        };
        try {
            const response = await post(endpoint, filter);
            if (response?.res) {
                alert("Work Day Successfully Saved.");
                if ($("#manageForUserType").val() == 'USER') {
                    // await syncCareDays($("#service_user_filter").val(), $("#service_user_filter option:selected").text());
                    // await syncEvents($("#service_user_filter").val(), $("#service_user_filter option:selected").text(), $("#visit_category_for_service_user").val(), $("#visit_status_for_service_user").val());
                }


                if ($("#manageForUserType").val() == 'STAFF') {
                    // await syncWorkDays($("#employee_filter").val(), $("#employee_filter option:selected").text());
                    // await syncLeaves($("#employee_filter").val(), $("#employee_filter option:selected").text());
                    // await syncEmployeeEvents($("#employee_filter").val(), $("#employee_filter option:selected").text(), $("#schedule_type").val(), $("#visit_status_for_employee").val());
                }
            } else {
                alert(response?.msg)
            }
        } catch (error) {
            console.error('Error fetching data:', error);  // Handle any errors
        }
    }

    const updateEmployeeWorkDay = async () => {
        const endpoint = "employees/employee_work_days_for_schedule/update";
        const filter = {
            id: $("#id").val(),
            server_id: server_id,
            staff_id: FilterPayload?.staff_id,
            year: FilterPayload?.shift_start_date.split('-')[0],
            month: FilterPayload?.shift_start_date.split('-')[1],
            shift_start_date: FilterPayload?.shift_start_date,
            shift_start_time: convertTo12HourFormat(FilterPayload?.shift_start_time),
            shift_end_date: FilterPayload?.shift_end_date,
            shift_end_time: convertTo12HourFormat(FilterPayload?.shift_end_time),
            is_break_alloted: FilterPayload?.is_break_alloted,
            break_start_date: FilterPayload?.break_start_date,
            break_start_time: convertTo12HourFormat(FilterPayload?.break_start_time),
            break_end_date: FilterPayload?.break_end_date,
            break_end_time: convertTo12HourFormat(FilterPayload?.break_end_time),
        };
        try {
            const response = await post(endpoint, filter);
            if (response?.res) {
                alert("Work Day Successfully Updated.");
                if ($("#manageForUserType").val() == 'USER') {
                    // await syncCareDays($("#service_user_filter").val(), $("#service_user_filter option:selected").text());
                    // await syncEvents($("#service_user_filter").val(), $("#service_user_filter option:selected").text(), $("#visit_category_for_service_user").val(), $("#visit_status_for_service_user").val());
                }


                if ($("#manageForUserType").val() == 'STAFF') {
                    // await syncWorkDays($("#employee_filter").val(), $("#employee_filter option:selected").text());
                    // await syncLeaves($("#employee_filter").val(), $("#employee_filter option:selected").text());
                    // await syncEmployeeEvents($("#employee_filter").val(), $("#employee_filter option:selected").text(), $("#schedule_type").val(), $("#visit_status_for_employee").val());
                }
            } else {
                alert(response?.msg)
            }
        } catch (error) {
            console.error('Error fetching data:', error);  // Handle any errors
        }
    }






    return (
        <>
            <div className={`modal fade ${ModalId == 'createNewEmployeeWorkDayModal' ? 'show' : ''}`} id="createNewEmployeeWorkDayModal" tabindex="-1" role="dialog"
                aria-labelledby="createNewEmployeeWorkDayModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="createNewEmployeeWorkDayModalLabel">Work Days</h5>
                            <button type="button" className="close" onClick={() => showModalAndTab('', '')}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <input type="hidden" id="year_WorkDay" />
                                <input type="hidden" id="month_WorkDay" />
                                <div className="form-group">
                                    <label>Staff</label>
                                    <select className="form-select" id="staff_id_WorkDay" onClick={handleChange}>
                                        <option value=""> - Select - </option>
                                        {
                                            Employee && Employee?.length > 0 ?
                                                Employee?.map((val, index) => (
                                                    <option value={`${val?.username}`}> {`${val?.full_name} (${val?.email})`} </option>
                                                ))
                                                : ''
                                        }
                                    </select>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Shift Start Date</label>
                                            <input className="form-control" type="date" id="shift_start_date_WorkDay" onClick={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Shift Start Time</label>
                                            <input className="form-control" type="time" id="shift_start_time_WorkDay" onClick={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Shift End Date</label>
                                            <input className="form-control" type="date" id="shift_end_date_WorkDay" onClick={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Shift End Time</label>
                                            <input className="form-control" type="time" id="shift_end_time_WorkDay" onClick={handleChange} />
                                        </div>
                                    </div>
                                    <div className="container-fluid">
                                        <div className="form-group text-right">
                                            <div className="pretty p-switch p-fill">
                                                <input type="checkbox" id="is_break_alloted_WorkDay" onClick={handleChecked} />
                                                <div className="state p-success">
                                                    <label>Do you want to add break ?</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Break Start Date</label>
                                            <input className="form-control" type="date" id="break_start_date_WorkDay" onClick={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Break Start Time</label>
                                            <input className="form-control" type="time" id="break_start_time_WorkDay" onClick={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Break End Date</label>
                                            <input className="form-control" type="date" id="break_end_date_WorkDay" onClick={handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Break End Time</label>
                                            <input className="form-control" type="time" id="break_end_time_WorkDay" onClick={handleChange} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => showModalAndTab('', '')}>Close</button>
                            <button type="button" className="btn btn-primary" id="saveEmployeeWorkDay" onClick={saveEmployeeWorkDay}>Save</button>
                            <button type="button" className="btn btn-primary" id="updateEmployeeWorkDay" onClick={updateEmployeeWorkDay}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
