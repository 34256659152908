import React, { useState } from 'react'

export default function CreateNewEventModal({ post, ServiceUser, ModalId, setModalId, TabId, setTabId, showModalAndTab }) {
    const [FilterPayload, setFilterPayload] = useState({});

    const handleChange = e => {
        const { name, value } = e?.target;
        setFilterPayload({
            ...FilterPayload,
            [name]: value
        })
    }

    const handleChecked = e => {
        const { name, checked, value } = e?.target;
        if (checked) {
            setFilterPayload({
                ...FilterPayload,
                [name]: value
            })
        } else {
            setFilterPayload({
                ...FilterPayload,
                [name]: ''
            })
        }
    }

    const getTimeDifferenceInSeconds = (dateTime2, dateTime1) => {
        // Parse the datetime strings into Date objects
        const date1 = new Date(dateTime1);
        const date2 = new Date(dateTime2);

        // Get the difference in milliseconds
        const differenceInMilliseconds = Math.floor(date1 - date2);

        // Convert milliseconds to seconds
        const differenceInSeconds = differenceInMilliseconds / 1000;

        return differenceInSeconds;
    }


    const handleAddEventForm = () => {
        const { visit_category, service_user, start_date, start_time, end_date, end_time, allday, is_notified } = FilterPayload;

        const startdatetime = start_date + "T" + (allday ? "00:00" : start_time) + (start_time.split(":").length > 2 ? "" : ":00")
        const enddatetime = end_date + "T" + (allday ? "00:00" : end_time) + (end_time.split(":").length > 2 ? "" : ":00")
        //   console.log(getTimeDifferenceInSeconds(startdatetime, enddatetime))
        //   console.log(startdatetime, enddatetime)
        if (getTimeDifferenceInSeconds(startdatetime, enddatetime) > 0 || (getTimeDifferenceInSeconds(startdatetime, enddatetime) == 0 && allday)) {
            // addNewEventInCalendar(startdatetime, enddatetime, allday, service_user, visit_category, is_notified)
        } else {
            alert("Invalid Start Date Time & End Date Time.")
        }
    }

    const handleUpdateEventForm = () => {
        const { visit_category, service_user, start_date, start_time, end_date, end_time, allday, is_notified } = FilterPayload;

        const startdatetime = start_date + "T" + (allday ? "00:00" : start_time) + (start_time.split(":").length > 2 ? "" : ":00")
        const enddatetime = end_date + "T" + (allday ? "00:00" : end_time) + (end_time.split(":").length > 2 ? "" : ":00")
        //   console.log(getTimeDifferenceInSeconds(startdatetime, enddatetime))
        //   console.log(startdatetime, enddatetime)
        if (getTimeDifferenceInSeconds(startdatetime, enddatetime) > 0 || (getTimeDifferenceInSeconds(startdatetime, enddatetime) == 0 && allday)) {
            // addNewEventInCalendar(startdatetime, enddatetime, allday, service_user, visit_category, is_notified, 2, $("#_service_user_visit_details_schedule_id").val())
        } else {
            alert("Invalid Start Date Time & End Date Time.")
        }
    }


    return (
        <>
            <div className={`modal fade ${ModalId == 'createNewEventModal' ? 'show' : ''}`} id="createNewEventModal" tabindex="-1" role="dialog"
                aria-labelledby="createNewEventModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="createNewEventModalLabel">Event</h5>
                            <button type="button" className="close" onClick={() => showModalAndTab('', '')}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label>Visit Category</label>
                                    <select className="form-select" id="visit_category" name='visit_category' value={FilterPayload?.visit_category}>
                                        <option value=""> - Select - </option>
                                        <option value="CONTINUOUS"> Continuous </option>
                                        <option value="DAYCARES"> Day Cares </option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Service User</label>
                                    <select className="form-select" id="service_user" name='service_user' value={FilterPayload?.service_user}>
                                        <option value=""> - Select - </option>
                                        {
                                            ServiceUser && ServiceUser?.length > 0 ?
                                                ServiceUser?.map((val, index) => (
                                                    <option value={`${val?.username}`}> {`${val?.full_name} (${val?.email})`} </option>
                                                ))
                                                : ''
                                        }
                                    </select>
                                </div>
                                <div className="row">
                                    <div className="container-fluid">
                                        <div className="form-group text-right">
                                            <div className="pretty p-switch p-fill">
                                                <input type="checkbox" id="allday" name='allday' value={true} checked={FilterPayload?.allday} />
                                                <div className="state p-success">
                                                    <label>All Day</label>
                                                </div>
                                            </div>
                                            <div className="pretty p-switch p-fill">
                                                <input type="checkbox" id="is_notified" name='is_notified' value={true} checked={FilterPayload?.is_notified} />
                                                <div className="state p-success">
                                                    <label>Notify</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Start Date</label>
                                            <input className="form-control" type="date" id="start_date" name='start_date' value={FilterPayload?.start_date} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Start Time</label>
                                            <input className="form-control" type="time" id="start_time" name='start_time' value={FilterPayload?.start_time} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>End Date</label>
                                            <input className="form-control" type="date" id="end_date" name='end_date' value={FilterPayload?.end_date} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>End Time</label>
                                            <input className="form-control" type="time" id="end_time" name='end_time' value={FilterPayload?.end_time} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={() => showModalAndTab('', '')}>Close</button>
                            <button type="button" className="btn btn-primary" id="saveServiceUserEvent" onClick={handleAddEventForm}>Save</button>
                            <button type="button" className="btn btn-primary" id="updateServiceUserEvent" onClick={handleUpdateEventForm}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
