import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';
import { retrieveGrossMarginOverview, retrieveVisitStatisticsPercentageOverview } from './actions/Finance';

export default function Finance() {
  const {server_id, admin_id} = useParams();
  let [loading, setLoading] = useState(false);
  const [GrossMarginOverviewData, setGrossMarginOverviewData] = useState({})
  const [VisitStatisticsPercentageOverviewData, setVisitStatisticsPercentageOverviewData] = useState({})

  let dispatch = useDispatch();


  useEffect(() => {
    let filter = {
      uid: admin_id,
      server_id: server_id,
    }

    dispatch(retrieveGrossMarginOverview(filter)).then(response => {
      setGrossMarginOverviewData(response?.data);
    })
    dispatch(retrieveVisitStatisticsPercentageOverview(filter)).then(response => {
      setVisitStatisticsPercentageOverviewData(response?.data?.stats)
    })
  }, [server_id, admin_id])

  return (
    <>
      {loading ? (
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
      ) : (
        <>
          <div className="container-fluid">
            <div className="row">
              <h3 className='mt-5 mb-3 fg-theme'>Gross Margin Overview</h3>
              <div className="row mt-2">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>Invoice</p>
                        <h6>$ {GrossMarginOverviewData?.invoice ? GrossMarginOverviewData?.invoice : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>Pay</p>
                        <h6>$ {GrossMarginOverviewData?.pay ? GrossMarginOverviewData?.pay : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>Travel Distance Pay</p>
                        <h6>$ {GrossMarginOverviewData?.travelDistancePay ? GrossMarginOverviewData?.travelDistancePay : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>Travel Time Pay</p>
                        <h6>$ {GrossMarginOverviewData?.travelTimePay ? GrossMarginOverviewData?.travelTimePay : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>Gross Margin</p>
                        <h6>$ {GrossMarginOverviewData?.grossMargin ? GrossMarginOverviewData?.grossMargin : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>Gross Margin Percentage</p>
                        <h6>$ {GrossMarginOverviewData?.grossMarginPercentage ? GrossMarginOverviewData?.grossMarginPercentage : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <h3 className='mt-5 mb-3 fg-theme'>Visit Statistic Percentage Overview (All)</h3>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>15 Minutes</p>
                        <h6>$ {VisitStatisticsPercentageOverviewData && VisitStatisticsPercentageOverviewData["15minutes"] ? VisitStatisticsPercentageOverviewData["15minutes"] : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div><div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>30 Minutes</p>
                        <h6>$ {VisitStatisticsPercentageOverviewData && VisitStatisticsPercentageOverviewData["30minutes"] ? VisitStatisticsPercentageOverviewData["30minutes"] : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div><div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>45 Minutes</p>
                        <h6>$ {VisitStatisticsPercentageOverviewData && VisitStatisticsPercentageOverviewData["45minutes"] ? VisitStatisticsPercentageOverviewData["45minutes"] : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div><div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>60 Minutes</p>
                        <h6>$ {VisitStatisticsPercentageOverviewData && VisitStatisticsPercentageOverviewData["60minutes"] ? VisitStatisticsPercentageOverviewData["60minutes"] : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div><div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>2 Hours</p>
                        <h6>$ {VisitStatisticsPercentageOverviewData && VisitStatisticsPercentageOverviewData["2hours"] ? VisitStatisticsPercentageOverviewData["2hours"] : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div><div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>Others</p>
                        <h6>$ {VisitStatisticsPercentageOverviewData?.others ? VisitStatisticsPercentageOverviewData?.others : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div><div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-2 mt-2 mb-2 d-grid">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-grid align-items-center no-gutters p-3 text-center">
                        <p>Total</p>
                        <h6>$ {VisitStatisticsPercentageOverviewData?.total ? VisitStatisticsPercentageOverviewData?.total : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
