import React, { useState } from 'react'

export default function AssignEmployeeModal({ post, ModalId, setModalId, TabId, setTabId, showModalAndTab }) {
  const [FilterPayload, setFilterPayload] = useState({});

  const handleChange = e => {
      const { name, value } = e?.target;
      setFilterPayload({
          ...FilterPayload,
          [name]: value
      })
  }

  const handleChecked = e => {
      const { name, checked, value } = e?.target;
      if (checked) {
          setFilterPayload({
              ...FilterPayload,
              [name]: value
          })
      } else {
          setFilterPayload({
              ...FilterPayload,
              [name]: ''
          })
      }
  }

  return (
    <>
      <div className={`modal fade ${ModalId == 'assignEmployeeModal' ? 'show' : ''}`} id="assignEmployeeModal" tabindex="-1" role="dialog"
        aria-labelledby="assignEmployeeModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="assignEmployeeModalLabel">Assign Employee</h5>
              <button type="button" className="close" onClick={() => showModalAndTab('', '')}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ maxHeight: "50vh", overflowY: "scroll" }}>
              <div className="container-fluid fc d-flex justify-space-between" style={{flexDirection: "row"}}>
                <button type="button" id="assignRecommendedEmployeeBTN" className="fc-button fc-button-primary active">Recommended
                  Employees</button>
                <button type="button" id="assignAllEmployeeBTN" className="fc-button fc-button-primary">Employee List</button>
              </div>
              <div className="container-fluid" id="recommendedEmployees">
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
