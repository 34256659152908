import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';

import { retrieve, update } from './actions/Task'

export default function Task() {
  const { server_id, admin_id } = useParams();
  const [TaskData, setTaskData] = useState([]);
  const [loading, setLoading] = useState(true);


  const dispatch = useDispatch();
  useEffect(() => {
    fetchTask(server_id, admin_id);
  }, [server_id, admin_id]);


  let fetchTask = (server_id, admin_id) => {
    setLoading(true);
    let data = {
      server_id: server_id ? server_id : '',
      admin_id: admin_id ? admin_id : '',
      limit: 1,
      last_id: '',
      first_id: '',
    };
    dispatch(retrieve(data)).then((response) => {
      setLoading(false);
      // console.log(response)
      setTaskData(response?.data);
    });
  }

  let handleAccess = (device_id, access) => {
    let data = {
      server_id: server_id ? server_id : '',
      admin_id: admin_id ? admin_id : '',
      device_id: device_id,
      access: access,
    }
    dispatch(update(data)).then((response) => {
      // console.log(response);
      setLoading(false);
      fetchTask(server_id, admin_id);
    });
  }


  let formatDateTime = (date) => {
    return date;
    console.log(date)
    // if(date && date.length > 0 && time && time.length > 0 && format == 'd/m/y H:i a') {
    //   // console.log(date, time)
    //   let tempDay = date.split('/')[0]
    //   let tempMonth = date.split('/')[1]
    //   let tempYear = date.split('/')[2]
    //   let time_ = time.split(' ')[0];
    //   let hour = time_.split(':')[0];
    //   hour = parseInt(time_.split(':')[0]);
    //   let minute = time_.split(':')[1];
    //   if(time.split(' ')[1] == 'PM') {
    //     return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? hour < 10 ? hour + 12 : hour + 12 : hour) + ':' + minute + ':00').toISOString();
    //   } else {
    //     return new Date(tempYear + '-' + tempMonth + '-' + tempDay + ' ' + (hour < 12 ? '0' + hour : hour) + ':' + minute + ':00').toISOString();
    //   }
    // } else {
    //   return new Date().toISOString();
    // }
  }
  return (
    <>
      {loading ?
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
        :
        <>
          <div className='row'>
            <h4 className='fg-theme mb-2 mt-2'>Task</h4>
            {
              TaskData && TaskData?.length > 0 ?
                TaskData.map((val, index) => {
                  return(
                    <div className='col-md-4 mt-1'>
                      <div className='card'>
                        <div className='card-body'>
                          <h4 className='fg-theme mb-2 mt-2'>{val?.title ? val?.title : ''}</h4>
                          {/* <h4 className='fg-theme mb-2 mt-2'>{val?.admin_user ? val?.admin_user : ''}</h4> */}
                          <p>Type : {val?.type}</p>
                          <p>Notification : {val?.notification}</p>
                          {/* <p>Staff : {val?.staff}</p> */}
                          {/* <p>Size : {val?.size}</p> */}
                          <p>Marked At : {Date(val?.markedAt)}</p>
                          {/* <p>Create Date : {formatDateTime(val?.added_on)}</p> */}
                          {/* <p>Deleted : <i className={`fa fa-${!val?.status?'toggle-on fg-green':'toggle-off fg-red'}`}></i></p>
                          <p>Status : <span className='text-uppercase'>{val?.status}</span></p> */}
                          {/* <p>Access : {val.access?'TRUE':'FALSE'}</p> */}
                        </div>  
                      </div>
                    </div>
                  )
                })
              : ''
            }
          </div>
        </>
      }
    </>
  )
}