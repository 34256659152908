import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { NavLink, useParams } from 'react-router-dom';

import { Loader, Placeholder } from 'rsuite';

import ArchitectureServerLeadsAddModal from './architectureServerLeads_add';

import Popup from '../../../components/popup';


import 'sweetalert/dist/sweetalert.css';

import SweetAlert from 'sweetalert-react';

import {
    retrieve, trash,
    retrievePending,
    retrieveConverted,
} from './actions/arcServerLeads';

export default function ArchitectureServerLeads() {
    const { server_id } = useParams();
    const server_converted_leads_data = useSelector(state => state.ARCServerConvertedLeadsReducer, shallowEqual);
    const [createModalVisibility, setCreateModalVisibility] = useState(false);
    // For Pagination Part
    const [firstIdHandlerForPagination, setFirstIdHandlerForPagination] = useState([]);
    const [lastIdHandlerForPagination, setLastIdHandlerForPagination] = useState([]);
    // For Pagination Part

    let handleCreateModalVisibility = () => {
        setCreateModalVisibility(!createModalVisibility);
    }

    let updateParentState = () => {
        setCreateModalVisibility(!createModalVisibility);
    }
    const initial_single_data = {
        id: '',
        record_data: {}
    };
    const [single_data, setSingleData] = useState(initial_single_data);
    const [record_length, setRecordLength] = useState(10);
    const [page, setPage] = useState(0);
    const [showTrashAlert, setShowTrashAlert] = useState({ show: false });
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    const fetchConvertedLeads = recordLength => {
        setLoading(true);
        let data = {
            server_id: server_id,
            limit: parseInt(recordLength),
            last_id: '',
            first_id: '',
        };
        dispatch(retrieveConverted(data)).then((response) => {
            setLoading(false);
            // For Pagination Part
            let firstIDArr = firstIdHandlerForPagination;
            let lastIDArr = lastIdHandlerForPagination;
            firstIDArr.push(response && response.first_id);
            lastIDArr.push(response && response.last_id);
            setFirstIdHandlerForPagination(firstIDArr);
            setLastIdHandlerForPagination(lastIDArr);
            // For Pagination Part
        });
    }

    useEffect(() => {
        fetchConvertedLeads(record_length);
    }, []);

    let handleChange = (e) => {
        if (e.target.name == 'record_length') {
            setRecordLength(e.target.value);
            fetchConvertedLeads(e.target.value);
        }
    }

    let handlePrev = () => {
        setLoading(true);
        let data = {
            server_id: server_id,
            limit: record_length,
            last_id: '',
            first_id: firstIdHandlerForPagination[firstIdHandlerForPagination.length - 2],
        };
        dispatch(retrieveConverted(data)).then(() => {
            setPage(page > 0 ? page - 1 : page)
            setLoading(false)
            // For Pagination Part
            let firstIDArr = firstIdHandlerForPagination.slice(0, firstIdHandlerForPagination.length - 1);
            let lastIDArr = lastIdHandlerForPagination.slice(0, lastIdHandlerForPagination.length - 1);
            setFirstIdHandlerForPagination(firstIDArr);
            setLastIdHandlerForPagination(lastIDArr);
            // For Pagination Part
        });
    }
    let handleNext = () => {
        setLoading(true);
        setPage((
            page <= (
                parseInt((
                    server_converted_leads_data &&
                        server_converted_leads_data.data &&
                        server_converted_leads_data.data.total ? server_converted_leads_data.data.total : 1
                ) / record_length) + 1
            ))
            ? page + 1
            : page
        );
        let data = {
            server_id: server_id,
            limit: record_length,
            last_id: server_converted_leads_data.data.last_id,
            first_id: '',
        };
        dispatch(retrieveConverted(data)).then((response) => {
            // For Pagination Part
            let firstIDArr = firstIdHandlerForPagination;
            let lastIDArr = lastIdHandlerForPagination;
            firstIDArr.push(response && response.first_id);
            lastIDArr.push(response && response.last_id);
            setFirstIdHandlerForPagination(firstIDArr);
            setLastIdHandlerForPagination(lastIDArr);
            // For Pagination Part
            setLoading(false)
        });
    }






    let handleEdit = (record) => {
        setSingleData(
            {
                sector_id: record.id,
                record_data: record
            }
        )
    }

    let handleShowTrashAlert = (uid) => {
        // console.log(showTrashAlert, uid)
        setShowTrashAlert({ uid: uid, show: !showTrashAlert.show });
    }

    let handleAlertResponse = () => {
        handleDelete();
    }

    let handleDelete = () => {
        setLoading(true);
        let data = {
            server_id: server_id,
            lead_id: showTrashAlert.uid,
        };
        dispatch(trash(data)).then(() => {
            setLoading(true);
            data = {
                server_id: server_id,
                limit: record_length,
                last_id: '',
                first_id: '',
            };
            dispatch(retrieveConverted(data)).then(() => {
                setLoading(false);
                setTimeout(() => {
                    setShowTrashAlert({ show: !showTrashAlert.show });
                    // console.log(!showTrashAlert.show)
                }, 1000);
            });
        });
    }




    return (

        <>
            {loading ?
                <div>
                    <Placeholder.Paragraph rows={8} />
                    <Loader center content="loading" />
                </div>
                :
                <>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h3 className="text-dark mb-0">Converted Leads</h3>
                        <a className="btn btn-primary btn-sm d-inline-block ml-15" role="button" href="javascript:void(0)" onClick={handleCreateModalVisibility}>
                            <i className="fas fa-plus fa-sm"></i>&nbsp;<span className='hidden-xs'>Add New Lead</span>
                        </a>
                        <Popup visibility_status={createModalVisibility} updateParentState={updateParentState}><ArchitectureServerLeadsAddModal updateParentState={updateParentState} /></Popup>

                    </div>
                    <div className="card shadow hidden-xs">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6 text-nowrap">
                                    <div id="dataTable_length" className="dataTables_length" aria-controls="dataTable">
                                        <label className="form-label">Show&nbsp;
                                            <select className="d-inline-block form-select form-select-sm" name='record_length' defaultValue={record_length} onChange={handleChange}>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive table mt-2" id="dataTable" role="grid" aria-describedby="dataTable_info">
                                <table className="table my-0" id="dataTable">
                                    <thead>
                                        <tr>
                                            <th>type</th>
                                            <th>email</th>
                                            <th>full_name</th>
                                            <th>poa</th>
                                            <th>nhs_number</th>
                                            <th>postal_code</th>
                                            <th>created_at</th>
                                            <th style={{ width: "110px" }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            server_converted_leads_data &&
                                                server_converted_leads_data.data &&
                                                server_converted_leads_data.data.leads &&
                                                server_converted_leads_data.data.leads.length > 0 ?
                                                server_converted_leads_data.data.leads.map((val, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{val.type}</td>
                                                            <td>{val.email}</td>
                                                            <td>{val.full_name}</td>
                                                            <td>{val.poa}</td>
                                                            <td>{val.nhs_number}</td>
                                                            <td>{val.postal_code}</td>
                                                            <td>{val.created_at}</td>
                                                            <td>
                                                                <NavLink to={`/Architecture/Servers/${server_id}/Leads/${val.id}/Edit`}><button type='button' className='btn btn-light btn-sm mr-15'><i className="fas fa-edit"></i></button></NavLink>
                                                                <button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleShowTrashAlert(val.id)}><i className="fas fa-trash fg-theme"></i></button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                : ''
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-md-6 align-self-center">
                                    <p id="dataTable_info" className="dataTables_info" role="status" aria-live="polite">Total Records {server_converted_leads_data && server_converted_leads_data.data && server_converted_leads_data.data.total ? server_converted_leads_data.data.total : 0}</p>
                                </div>
                                <div className="col-md-6">
                                    <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                        <ul className="pagination">
                                            {page ?
                                                <li className={page ? "page-item" : "page-item disabled"} onClick={handlePrev}><a className="page-link" aria-label="Previous" href="#"><span aria-hidden="true">«</span> Previous</a></li>
                                                : ''}
                                            {(page <= (parseInt((server_converted_leads_data && server_converted_leads_data.data && server_converted_leads_data.data.total ? server_converted_leads_data.data.total : 1) / record_length) - 1)) ?
                                                <li className={(page <= (parseInt((server_converted_leads_data && server_converted_leads_data.data && server_converted_leads_data.data.total ? server_converted_leads_data.data.total : 1) / record_length) - 1)) ? "page-item" : "page-item disabled"} onClick={handleNext}><a className="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span></a></li>
                                                : ''}
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <SweetAlert
                                show={showTrashAlert.show}
                                title=""
                                text="Are you sure you want to permanently delete this record?"
                                onCancel={() => handleShowTrashAlert(false)}
                                showCancelButton
                                onConfirm={handleAlertResponse}
                            />
                        </div>
                    </div>
                    <div className="container-fluid shown-xs">
                        <div className="row">
                            <div className="col-md-12 text-nowrap">
                                <div id="dataTable_length" className="dataTables_length" aria-controls="dataTable">
                                    <label className="form-label">Show&nbsp;
                                        <select className="d-inline-block form-select form-select-sm" name='record_length' defaultValue={record_length} onChange={handleChange}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {
                                server_converted_leads_data &&
                                    server_converted_leads_data.data &&
                                    server_converted_leads_data.data.leads &&
                                    server_converted_leads_data.data.leads.length > 0 ?
                                    server_converted_leads_data.data.leads.map((val, index) => {
                                        return (
                                            <div className="col-md-6 col-sm-6 col-xs-12 mt-2">
                                                <div className="row">
                                                    <div className="card shadow">
                                                        <div className="row">
                                                            <div className='card-header text-right'>
                                                                <NavLink to={`/Architecture/Servers/${server_id}/Leads/${val.id}/Edit`}><button type='button' className='btn btn-light btn-sm mr-15'><i className="fas fa-edit"></i></button></NavLink>
                                                                <button type='button' className='btn btn-light btn-sm mr-15' onClick={() => handleShowTrashAlert(val.id)}><i className="fas fa-trash fg-theme"></i></button>
                                                            </div>
                                                            <div className="card-body">
                                                                <p>Type: {val.type}</p>
                                                                <p>Email : {val.email}</p>
                                                                <p>Name : {val.full_name}</p>
                                                                <p>POA : {val.poa}</p>
                                                                <p>NHS No. : {val.nhs_number}</p>
                                                                <p>Postal Code : {val.postal_code}</p>
                                                                <p>Created At : {val.created_at}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : ''
                            }
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-12 align-self-center">
                                <p id="dataTable_info" className="dataTables_info text-center" role="status" aria-live="polite">Total Records {server_converted_leads_data && server_converted_leads_data.data && server_converted_leads_data.data.total ? server_converted_leads_data.data.total : 0}</p>
                            </div>
                            <div className="col-md-12">
                                <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                                    <ul className="pagination">
                                        {page ?
                                            <li className={page ? "page-item" : "page-item disabled"} onClick={handlePrev}><a className="page-link" aria-label="Previous" href="#"><span aria-hidden="true">«</span> Previous</a></li>
                                            : ''}
                                        {(page <= (parseInt((server_converted_leads_data && server_converted_leads_data.data && server_converted_leads_data.data.total ? server_converted_leads_data.data.total : 1) / record_length) - 1)) ?
                                            <li className={(page <= (parseInt((server_converted_leads_data && server_converted_leads_data.data && server_converted_leads_data.data.total ? server_converted_leads_data.data.total : 1) / record_length) - 1)) ? "page-item align-right" : "page-item align-right disabled"} onClick={handleNext}><a className="page-link" aria-label="Next" href="#">Next <span aria-hidden="true">»</span></a></li>
                                            : ''}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
