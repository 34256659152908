import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Avatar from '../../../assets/avatars/avatar1.jpeg'
import { Loader, Placeholder } from 'rsuite';

import Iframe from 'react-iframe';
// import '../Extras/profile.css';
import ServerServiceUserVisitStatisticsGraph from '../architectureDashboard/components/graphs/ServiceUserVisitStatistics/ServiceUserVisitStatistics'
import { NavLink, useParams } from 'react-router-dom';


import eventIcon from '../../../assets/architecture/events.png';

import visitManagmentIC from '../../../assets/architecture/grid_management.png';

import business_devIC from '../../../assets/architecture/grid_business.png';

import financeIC from '../../../assets/architecture/grid_finance.png';

import planningIC from '../../../assets/architecture/grid_planning.png';

import NhsDataIC from '../../../assets/architecture/NHS.png';

import employIC from '../../../assets/architecture/grid_employees.png';

import serviceUserIC from '../../../assets/architecture/grid_management.png';

import LocalIC from '../../../assets/architecture/grid_users.png';

import HealthAuthIC from '../../../assets/architecture/organisations.png';

import TravelDistanceIC from '../../../assets/architecture/address.png';

import CapsuleIC from '../../../assets/architecture/medical_requests.png';

import {
  retrieveSingle
} from "./actions/arcServerAdmin";



export default function ArchitectureAdminDetails() {
  const InitialData = {

  };
  const { server_id, admin_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState(InitialData);
  const [statusData, setStatusData] = useState({});
  const [date_of_birth, setDateOfBirth] = useState('')
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    let data = {
      server_id: server_id ? server_id : '',
      username: admin_id ? admin_id : '',
      limit: 1,
      last_id: '',
      first_id: '',
    };
    dispatch(retrieveSingle(data)).then((response) => {
      // console.log(response)
      setFormData(response);
      let monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      let x = response?.dob ? response?.dob : '';
      // console.log(x.split('/'))
      if (x.split('/').length > 1)
        x = x.split('/')[2] + '-' + x.split('/')[1] + '-' + x.split('/')[0];
      let xdate;
      if (x.length > 0)
        xdate = new Date(x).toISOString().split('T')[0];
      else
        xdate = new Date().toISOString().split('T')[0];
      // let xdate = x.split('T')[0];
      let xyear = xdate.split('-')[0];
      let xmonth = monthArr[parseInt(xdate.split('-')[1]) - 1];
      let xday = xdate.split('-')[2];
      setDateOfBirth(xmonth + ' ' + xday + ', ' + xyear);
      setLoading(false);
    });
  }, [server_id, admin_id]);

  return (
    <>
      {loading ? (
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
      ) : (
        <>
          <div className="row">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 ">
                  <div className="row">
                    <div className="card">
                      <div className="row">
                        <div className="card-body">
                          <div className="row">
                            <div className='container-fluid text-right'>
                              <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/Courses`}>
                                <i className={`fas fa-graduation-cap ml-5 p-cursor`}></i>
                              </NavLink>
                              <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/Edit`}>
                                <i className={`fas fa-pen ml-5 p-cursor`}></i>
                              </NavLink>
                              <i className={`fa fa-circle ${formData?.activeNow ? 'fg-green' : ''} ml-5`}></i>
                            </div>
                            <div className='container-fluid img-container text-center mb-2'>
                              <img src={formData?.profilePicture} />
                            </div>
                            <div className='container-fluid text-center'>
                              <h4 className='f-poppins fg-theme mb-2'>{formData.full_name}</h4>
                              <p className='f-poppins mb-2'><i className={`fa fa-phone-alt fg-theme`}></i> &nbsp; {formData.phone_number}</p>
                              <p className='f-poppins mb-2'><i className={`fa fa-envelope fg-theme`}></i> &nbsp; {formData.email}</p>
                              <p className='f-poppins mb-2'><i className={`fa fa-calendar fg-theme`}></i> &nbsp; {date_of_birth}</p>
                              <p className='f-poppins mb-2'><i className={`fa fa-map-marker fg-theme`}></i> &nbsp; {formData.street} {formData.town} {formData.county} {formData.country} {formData.postalCode}</p>
                            </div>
                            <div className='container-fluid text-center'>
                              <div className='card-body p-0' style={{ overflow: 'hidden', borderRadius: '20px' }}>
                                <Iframe src={`https://maps.google.com/maps?q=${formData?.active_location ? formData?.active_location[0] : 0},${formData?.active_location ? formData?.active_location[1] : 0}&output=embed`} width="100%" height="480"></Iframe>
                                {/* <Iframe src={`https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3686.1061568683367!2d${formData?.active_location ? formData?.active_location[1] : 0}!3d${formData?.active_location ? formData?.active_location[0] : 0}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjLCsDMwJzAwLjciTiA4OMKwMjEnMjcuNSJF!5e0!3m2!1sen!2sin!4v1690974077802!5m2!1sen!2sin`} width="100%" height="480"></Iframe> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-4">
                      <div className="row">
                        <div className="card shadow border-start-primary py-2 mt-3">
                          <div className="row">
                            <div className="card-body">
                              <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/Event`}>
                                <div className="row align-items-center no-gutters">
                                  <div className="col-auto">
                                    <i className="fas fa-calendar fa-2x text-gray-300"> </i>
                                  </div>
                                  <div className="col me-2">
                                    <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme">
                                      <span>Events</span>
                                    </div>
                                    <div className="text-ash mb-0 Poppins">
                                      <span>{statusData.events !== 0 ? 'You have new unread events.' : 'No new events.'}</span>
                                    </div>
                                    <div className="fw-bold h3 Poppins mb-0 fg-theme">
                                      <span>{statusData.events !== 0 ? statusData.events : ''}</span>
                                    </div>
                                  </div>
                                  <div className="col-auto">
                                    <i className="fas fa-angle-right fa-2x text-gray-300"></i>
                                  </div>
                                </div>
                              </NavLink>

                              <hr />

                              <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/Message`}>
                                <div className="row align-items-center no-gutters">
                                  <div className="col-auto">
                                    <i className="fas fa-comments fa-2x text-gray-300"></i>
                                  </div>
                                  <div className="col me-2">
                                    <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme">
                                      <span>Message</span>
                                    </div>
                                    <div className="text-ash mb-0 Poppins">
                                      <span>{statusData.message !== 0 ? 'You have new unread message.' : 'No new message.'}</span>
                                    </div>
                                    <div className="fw-bold h3 Poppins mb-0 fg-theme">
                                      <span>{statusData.message !== 0 ? statusData.message : ''}</span>
                                    </div>
                                  </div>
                                  <div className="col-auto">
                                    <i className="fas fa-angle-right fa-2x text-gray-300"></i>
                                  </div>
                                </div>
                              </NavLink>

                              <hr />

                              <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/Task`}>
                                <div className="row align-items-center no-gutters">
                                  <div className="col-auto">
                                    <i className="fas fa-check fa-2x text-gray-300"></i>
                                  </div>
                                  <div className="col me-2">
                                    <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme">
                                      <span>Tasks</span>
                                    </div>
                                    <div className="text-ash mb-0 Poppins">
                                      <span>{statusData.tasks !== 0 ? 'You have new unread task.' : 'No new task.'}</span>
                                    </div>
                                    <div className="fw-bold h3 Poppins mb-0 fg-theme">
                                      <span>{statusData.tasks !== 0 ? statusData.tasks : ''}</span>
                                    </div>
                                  </div>
                                  <div className="col-auto">
                                    <i className="fas fa-angle-right fa-2x text-gray-300"></i>
                                  </div>
                                </div>
                              </NavLink>

                              <hr />

                              <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/Notification`}>
                                <div className="row align-items-center no-gutters">
                                  <div className="col-auto">
                                    <i className="fas fa-bell fa-2x text-gray-300"></i>
                                  </div>
                                  <div className="col me-2">
                                    <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme">
                                      <span>Notifications</span>
                                    </div>
                                    <div className="text-ash mb-0 Poppins">
                                      <span>{statusData.notifications !== 0 ? 'You have new unread notifications.' : 'No new notifications.'}</span>
                                    </div>
                                    <div className="fw-bold h3 Poppins mb-0 fg-theme">
                                      <span>{statusData.notifications !== 0 ? statusData.notifications : ''}</span>
                                    </div>
                                  </div>
                                  <div className="col-auto">
                                    <i className="fas fa-angle-right fa-2x text-gray-300"></i>
                                  </div>
                                </div>
                              </NavLink>

                              <hr />

                              <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/VisitAlert`}>
                                <div className="row align-items-center no-gutters">
                                  <div className="col-auto">
                                    <i className="fas fa-exclamation fa-2x text-gray-300 p-2"></i>
                                  </div>
                                  <div className="col me-2">
                                    <div className="text-uppercase fw-bold h5 mb-1 Poppins fg-theme">
                                      <span>Visit Alert</span>
                                    </div>
                                    <div className="text-ash mb-0 Poppins">
                                      <span>{statusData.visitAlerts !== 0 ? 'You have new unread alert.' : 'No new alert.'}</span>
                                    </div>
                                    <div className="fw-bold h3 Poppins mb-0 fg-theme">
                                      <span>{statusData.visitAlerts !== 0 ? statusData.visitAlerts : ''}</span>
                                    </div>
                                  </div>
                                  <div className="col-auto">
                                    <i className="fas fa-angle-right fa-2x text-gray-300"></i>
                                  </div>
                                </div>
                              </NavLink>
                            </div>
                          </div>
                        </div>



                        <div className='container-fluid p-0'>
                          <div className='row'>
                            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2'>
                              <div className='row'>
                                <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/AdminSharedAccess`}>
                                  <div className="card shadow py-1">
                                    <div className="card-body text-center">
                                      <p className="Poppins fg-theme h6">{'Shared Access'}</p>
                                    </div>
                                  </div>
                                </NavLink>
                              </div>
                            </div>
                            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2'>
                              <div className='row'>
                                <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/AdminScheduledVisits`}>
                                  <div className="card shadow py-1">
                                    <div className="card-body text-center">
                                      <p className="Poppins fg-theme h6">{'All Visits'}</p>
                                    </div>
                                  </div>
                                </NavLink>
                              </div>
                            </div>
                            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2'>
                              <div className='row'>
                                <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/AdminRequestStaffTransfer`}>
                                  <div className="card shadow py-1">
                                    <div className="card-body text-center">
                                      <p className="Poppins fg-theme h6">{'Request Staff Transfer'}</p>
                                    </div>
                                  </div>
                                </NavLink>
                              </div>
                            </div>
                            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2'>
                              <div className='row'>
                                <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/AdminSupportTicket`}>
                                  <div className="card shadow py-1">
                                    <div className="card-body text-center">
                                      <p className="Poppins fg-theme h6">{'Support Ticket'}</p>
                                    </div>
                                  </div>
                                </NavLink>
                              </div>
                            </div>
                            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2'>
                              <div className='row'>
                                <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/AdminPPERequestHistory`}>
                                  <div className="card shadow py-1">
                                    <div className="card-body text-center">
                                      <p className="Poppins fg-theme h6">{'PPE Request History'}</p>
                                    </div>
                                  </div>
                                </NavLink>
                              </div>
                            </div>
                            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2'>
                              <div className='row'>
                                <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/AdminPoliciesProcedures`}>
                                  <div className="card shadow py-1">
                                    <div className="card-body text-center">
                                      <p className="Poppins fg-theme h6">{'Policies & Procedures'}</p>
                                    </div>
                                  </div>
                                </NavLink>
                              </div>
                            </div>
                            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2'>
                              <div className='row'>
                                <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/AdminEmergencyAlert`}>
                                  <div className="card shadow py-1">
                                    <div className="card-body text-center">
                                      <p className="Poppins fg-theme h6">{'Emergency Alert'}</p>
                                    </div>
                                  </div>
                                </NavLink>
                              </div>
                            </div>
                            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2'>
                              <div className='row'>
                                <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/AdminCustomForms`}>
                                  <div className="card shadow py-1">
                                    <div className="card-body text-center">
                                      <p className="Poppins fg-theme h6">{'Custom Forms'}</p>
                                    </div>
                                  </div>
                                </NavLink>
                              </div>
                            </div>
                            {/* <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2'>
                        <div className='row'>
                        <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/AdminSocialCareNavigator`}>
                          <div className="card shadow py-1">
                            <div className="card-body text-center">
                              <p className="Poppins fg-theme h6">{'Social Care Navigator'}</p>
                            </div>
                          </div>
                        </NavLink>
                        </div>
                      </div> */}
                            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2'>
                              <div className='row'>
                                <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/AdminNotificationSettings`}>
                                  <div className="card shadow py-1">
                                    <div className="card-body text-center">
                                      <p className="Poppins fg-theme h6">{'Notification Settings'}</p>
                                    </div>
                                  </div>
                                </NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-6 p-0">
                      <div className="row">
                        <div className="col-12 col-md-6 col-lg-6 mt-2">
                          <div className="row">
                            <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/VisitManagement`}>
                              <div className="row m-0">
                                <div className="card shadow py-4">
                                  <div className="card-body text-center">
                                    <img
                                      src={visitManagmentIC}
                                      alt={'Visit Managment'}
                                      width="13%"
                                      className="box-image"
                                    />
                                    <p className="Poppins fg-theme h6">{'Visit Managment'}</p>
                                  </div>
                                </div>
                              </div>
                            </NavLink>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mt-2">
                          <div className="row">
                            <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/BusinessDevelopment`}>
                              <div className="row m-0">
                                <div className="card shadow py-4">
                                  <div className="card-body text-center">
                                    <img
                                      src={business_devIC}
                                      alt={'Business Development'}
                                      width="13%"
                                      className="box-image"
                                    />
                                    <p className="Poppins fg-theme h6">{'Business Development'}</p>
                                  </div>
                                </div>
                              </div>
                            </NavLink>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mt-2">
                          <div className="row">
                            <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/Finance`}>
                              <div className="row m-0">
                                <div className="card shadow py-4">
                                  <div className="card-body text-center">
                                    <img src={financeIC} alt={'Finance'} width="13%" className="box-image" />
                                    <p className="Poppins fg-theme h6">{'Finance'}</p>
                                  </div>
                                </div>
                              </div>
                            </NavLink>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mt-2">
                          <div className="row">
                            <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/Planning`}>
                              <div className="row m-0">
                                <div className="card shadow py-4">
                                  <div className="card-body text-center">
                                    <img
                                      src={planningIC}
                                      alt={'Planning'}
                                      width="13%"
                                      className="box-image"
                                    />
                                    <p className="Poppins fg-theme h6">{'Planning'}</p>
                                  </div>
                                </div>
                              </div>
                            </NavLink>
                          </div>
                        </div>
                        {/* <div className="col-12 col-md-6 col-lg-6 mt-2">
                      <div className="row">
                        <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/NHSData`}>
                          <div className="row m-0">
                            <div className="card shadow py-4">
                              <div className="card-body text-center">
                                <img src={NhsDataIC} alt={'NHS Data'} width="13%" className="box-image" />
                                <p className="Poppins fg-theme h6">{'NHS Data'}</p>
                              </div>
                            </div>
                          </div>
                        </NavLink>
                        </div>
                      </div> */}
                        <div className="col-12 col-md-6 col-lg-6 mt-2">
                          <div className="row">
                            <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/Employee`}>
                              <div className="row m-0">
                                <div className="card shadow py-4">
                                  <div className="card-body text-center">
                                    <img src={employIC} alt={'Employees'} width="13%" className="box-image" />
                                    <p className="Poppins fg-theme h6">{'Employees'}</p>
                                  </div>
                                </div>
                              </div>
                            </NavLink>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mt-2">
                          <div className="row">
                            <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/ServiceUsers`}>
                              <div className="row m-0">
                                <div className="card shadow py-4">
                                  <div className="card-body text-center">
                                    <img
                                      src={serviceUserIC}
                                      alt={'Service Users'}
                                      width="13%"
                                      className="box-image"
                                    />
                                    <p className="Poppins fg-theme h6">{'Service Users'}</p>
                                  </div>
                                </div>
                              </div>
                            </NavLink>
                          </div>
                        </div>
                        {/* <div className="col-12 col-md-6 col-lg-6 mt-2">
                      <div className="row">
                        <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/LocalAuthority`}>
                          <div className="row m-0">
                            <div className="card shadow py-4">
                              <div className="card-body text-center">
                                <img
                                  src={LocalIC}
                                  alt={'Local Authority'}
                                  width="13%"
                                  className="box-image"
                                />
                                <p className="Poppins fg-theme h6">{'Local Authority'}</p>
                              </div>
                            </div>
                          </div>
                        </NavLink>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-lg-6 mt-2">
                      <div className="row">
                        <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/HealthAuthority`}>
                          <div className="row m-0">
                            <div className="card shadow py-4">
                              <div className="card-body text-center">
                                <img
                                  src={HealthAuthIC}
                                  alt={'Health Authority'}
                                  width="13%"
                                  className="box-image"
                                />
                                <p className="Poppins fg-theme h6">{'Health Authority'}</p>
                              </div>
                            </div>
                          </div>
                        </NavLink>
                        </div>
                      </div> */}
                        <div className="col-12 col-md-6 col-lg-6 mt-2">
                          <div className="row">
                            <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/Run`}>
                              <div className="row m-0">
                                <div className="card shadow py-4">
                                  <div className="card-body text-center">
                                    <img
                                      src={TravelDistanceIC}
                                      alt={'Run'}
                                      width="13%"
                                      className="box-image"
                                    />
                                    <p className="Poppins fg-theme h6">{'Run'}</p>
                                  </div>
                                </div>
                              </div>
                            </NavLink>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 mt-2 mb-2">
                          <div className="row">
                            <NavLink to={`/Architecture/Servers/${server_id}/Admin/${admin_id}/Medicines`}>
                              <div className="row m-0">
                                <div className="card shadow py-4">
                                  <div className="card-body text-center">
                                    <img
                                      src={CapsuleIC}
                                      alt={'Medicines'}
                                      width="13%"
                                      className="box-image"
                                    />
                                    <p className="Poppins fg-theme h6">{'Medicines'}</p>
                                  </div>
                                </div>
                              </div>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <button className="alert alert-light text-start w-100">
                  <i className="far fa-user-circle ic-theme mx-2 fa-lg"></i>
                  Visits Management
                  <span className="float-right">
                    <i className="fa fa-chevron-right"></i>
                  </span>
                </button>
                <button className="alert alert-light text-start w-100">
                  <i className="far fa-user-circle ic-theme mx-2 fa-lg"></i>
                  Business Development
                  <span className="float-right">
                    <i className="fa fa-chevron-right"></i>
                  </span>
                </button>
                <button className="alert alert-light text-start w-100">
                  <i className="far fa-user-circle ic-theme mx-2 fa-lg"></i>
                  Finance
                  <span className="float-right">
                    <i className="fa fa-chevron-right"></i>
                  </span>
                </button>
                <button className="alert alert-light text-start w-100">
                  <i className="far fa-user-circle ic-theme mx-2 fa-lg"></i>
                  Planning
                  <span className="float-right">
                    <i className="fa fa-chevron-right"></i>
                  </span>
                </button>
                <button className="alert alert-light text-start w-100">
                  <i className="far fa-user-circle ic-theme mx-2 fa-lg"></i>
                  NHS Data
                  <span className="float-right">
                    <i className="fa fa-chevron-right"></i>
                  </span>
                </button>
                <button className="alert alert-light text-start w-100">
                  <i className="far fa-user-circle ic-theme mx-2 fa-lg"></i>
                  Employees
                  <span className="float-right">
                    <i className="fa fa-chevron-right"></i>
                  </span>
                </button>
                <button className="alert alert-light text-start w-100">
                  <i className="far fa-user-circle ic-theme mx-2 fa-lg"></i>
                  Service Users
                  <span className="float-right">
                    <i className="fa fa-chevron-right"></i>
                  </span>
                </button>
                <button className="alert alert-light text-start w-100">
                  <i className="far fa-user-circle ic-theme mx-2 fa-lg"></i>
                  Medication Identifier
                  <span className="float-right">
                    <i className="fa fa-chevron-right"></i>
                  </span>
                </button>
                <button className="alert alert-light text-start w-100">
                  <i className="far fa-user-circle ic-theme mx-2 fa-lg"></i>
                  Local Authority
                  <span className="float-right">
                    <i className="fa fa-chevron-right"></i>
                  </span>
                </button>
                <button className="alert alert-light text-start w-100">
                  <i className="far fa-user-circle ic-theme mx-2 fa-lg"></i>
                  Health Authority
                  <span className="float-right">
                    <i className="fa fa-chevron-right"></i>
                  </span>
                </button> */}
                  {/* <NavLink to={'/Architecture/Servers/:server_id/ServiceUser/:service_user_id/Details/Activities/'}>
                  <button className="alert alert-light text-start w-100">
                    <i className="far fa-user-circle ic-theme mx-2 fa-lg"></i>
                    Manage Activities
                    <span className="float-right">
                      <i className="fa fa-chevron-right"></i>
                    </span>
                  </button>
                </NavLink> */}

                  {/* <a href='/Architecture/Servers/shaderbytes.com/ServiceUser/DebDeb/CarePlan'>
                  <button className="alert alert-light text-start w-100">
                    <i className="far fa-user-circle ic-theme mx-2 fa-lg"></i>
                    Shift Time
                    <span className="float-right">
                      <i className="fa fa-chevron-right"></i>
                    </span>
                  </button>
                </a>
                <button className="alert alert-light text-start w-100">
                  <i className="far fa-user-circle ic-theme mx-2 fa-lg"></i>
                  Previous Visits
                  <span className="float-right">
                    <i className="fa fa-chevron-right"></i>
                  </span>
                </button>
                <button className="alert alert-light text-start w-100">
                  <i className="far fa-user-circle ic-theme mx-2 fa-lg"></i>
                  view Visits Statistics
                  <span className="float-right">
                    <i className="fa fa-chevron-right"></i>
                  </span>
                </button>
                <button className="alert alert-light text-start w-100">
                  <i className="far fa-user-circle ic-theme mx-2 fa-lg"></i>
                  Set Rate
                  <span className="float-right">
                    <i className="fa fa-chevron-right"></i>
                  </span>
                </button>
                <button className="alert alert-light text-start w-100">
                  <i className="far fa-user-circle ic-theme mx-2 fa-lg"></i>
                  DBS Certification
                  <span className="float-right">
                    <i className="fa fa-chevron-right"></i>
                  </span>
                </button>
                <button className="alert alert-light text-start w-100">
                  <i className="far fa-user-circle ic-theme mx-2 fa-lg"></i>
                  Car insurance
                  <span className="float-right">
                    <i className="fa fa-chevron-right"></i>
                  </span>
                </button>
                <button className="alert alert-light text-start w-100">
                  <i className="far fa-user-circle ic-theme mx-2 fa-lg"></i>
                  Next of Kin
                  <span className="float-right">
                    <i className="fa fa-chevron-right"></i>
                  </span>
                </button>
                <button className="alert alert-light text-start w-100">
                  <i className="far fa-user-circle ic-theme mx-2 fa-lg"></i>
                  Your Traning
                  <span className="float-right">
                    <i className="fa fa-chevron-right"></i>
                  </span>
                </button>
                <button className="alert alert-light text-start w-100">
                  <i className="far fa-user-circle ic-theme mx-2 fa-lg"></i>
                  Feedbacks
                  <span className="float-right">
                    <i className="fa fa-chevron-right"></i>
                  </span>
                </button> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

