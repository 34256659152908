import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { NavLink } from 'react-router-dom';


import Logo2 from '../assets/logo.png'
import Logo from '../assets/wt-logo.png'

import SidebarMenus from './sidebarMenus';
import { ReactSession } from 'react-client-session';
import MobileSidebar from './MobileSidebar';

ReactSession.setStoreType("localStorage");

export default function Header() {
    const [ShowMenu, setShowMenu] = useState(false);
    const [ShowSubMenu, setShowSubMenu] = useState(false);
    const [SubMenu, setSubMenu] = useState([]);
    const SidebarMenuJSON = {
        items: [{
            id: 1,
            menu_name: 'Architecture',
            type: 'accordian',
            logo: Logo,
            child: [{
                id: 101,
                menu_name: 'Dashboard',
                type: 'link',
                link: '/Architecture/Dashboard'
            }, {
                id: 102,
                menu_name: 'Servers',
                type: 'link',
                link: '/Architecture/Servers'
            }, {
                id: 103,
                menu_name: 'Company Leads',
                type: 'link',
                link: '/Architecture/CompanyLeads'
            }, {
                id: 103,
                menu_name: 'Activities',
                type: 'link',
                link: '/Architecture/Activities'
            }, {
                id: 104,
                menu_name: 'Types Of Service',
                type: 'link',
                link: '/Architecture/TypeOfService'
            }, {
                id: 105,
                menu_name: 'Support Tickets',
                type: 'link',
                link: '/Architecture/SupportTickets'
            }, {
                //     id: 104,
                //     menu_name: 'Governments',
                //     type: 'link',
                //     link: '/Architecture/Governments'
                // }, {
                //     id: 104,
                //     menu_name: 'Regulators',
                //     type: 'link',
                //     link: '/Architecture/Regulators'
                // }, {
                id: 104,
                menu_name: 'Attorneys',
                type: 'link',
                link: '/Architecture/Attorneys'
                // }, {
                //     id: 104,
                //     menu_name: 'Invoice',
                //     type: 'link',
                //     link: '/Architecture/Invoice'
                // }, {
                //     id: 104,
                //     menu_name: 'Payslip',
                //     type: 'link',
                //     link: '/Architecture/Payslip'
            }]
        }, {
            id: 2,
            menu_name: 'Social JobFinder',
            type: 'accordian',
            logo: Logo,
            child: [{
                id: 201,
                menu_name: 'Dashboard',
                type: 'link',
                link: '/SocialJobFinder/Dashboard'
            }, {
                id: 202,
                menu_name: 'Companies',
                type: 'link',
                link: '/SocialJobFinder/Companies'
                // }, {
                //     id: 203,
                //     menu_name: 'Applicants',
                //     type: 'link',
                //     link: '/SocialJobFinder/Applicants'
                // }, {
                //     id: 204,
                //     menu_name: 'Jobs',
                //     type: 'link',
                //     link: '/SocialJobFinder/Jobs'
                // }, {
                //     id: 205,
                //     menu_name: 'Support Tickets',
                //     type: 'link',
                //     link: '/SocialJobFinder/SupportTickets'
                // }, {
                // //     id: 206,
                // //     menu_name: 'Messages',
                // //     type: 'link',
                // //     link: '/SocialJobFinder/Messages'
                // // }, {
                //     id: 207,
                //     menu_name: 'Meetings',
                //     type: 'link',
                //     link: '/SocialJobFinder/Meetings'
                // }, {
                //     id: 208,
                //     menu_name: 'Sectors',
                //     type: 'link',
                //     link: '/SocialJobFinder/Sectors'
                // }, {
                //     id: 209,
                //     menu_name: 'General',
                //     type: 'link',
                //     link: '/SocialJobFinder/General'
                // }, {
                //     id: 210,
                //     menu_name: 'FAQs',
                //     type: 'link',
                //     link: '/SocialJobFinder/FAQs'
            }]
        }, {
            id: 3,
            menu_name: 'CQC',
            type: 'accordian',
            logo: Logo,
            child: [{
                id: 301,
                menu_name: 'Providers',
                type: 'link',
                link: '/CQC/Providers'
            }, {
                id: 302,
                menu_name: 'Locations',
                type: 'link',
                link: '/CQC/Locations'
            }, {
                id: 303,
                menu_name: 'Inspection Area Taxonomy',
                type: 'link',
                link: '/CQC/InspectionAreaTaxonomy'
                // }, {
                //     id: 304,
                //     menu_name: 'Organisation Re-registration',
                //     type: 'link',
                //     link: '/CQC/OrganisationReregistration'
                // }, {
                //     id: 305,
                //     menu_name: 'Reports',
                //     type: 'link',
                //     link: '/CQC/Reports'
            },],
        }, {
            id: 4,
            menu_name: 'E-Learning',
            type: 'accordian',
            logo: Logo,
            child: [{
                id: 401,
                menu_name: 'Dashboard',
                type: 'link',
                link: '/Learners/Dashboard'
            }, {
                id: 402,
                menu_name: 'Company',
                type: 'link',
                link: '/Learners/Company'
            }, {
                id: 403,
                menu_name: 'Learner User',
                type: 'link',
                link: '/Learners/Students'
            }, {
                id: 404,
                menu_name: 'Learner Course',
                type: 'link',
                link: '/Learners/Course'
            }, {
                id: 405,
                menu_name: 'Course Completion Report',
                type: 'link',
                link: '/Learners/CompanyCourseCompletionReport'
            }, {
                id: 406,
                menu_name: 'Company Course Report',
                type: 'link',
                link: '/Learners/CompanyCourse'
            }, {
                //     id: 402,
                //     menu_name: 'Locations',
                //     type: 'link',
                //     link: '/CQC/Locations'
                // }, {
                //     id: 403,
                //     menu_name: 'Inspection Area Taxonomy',
                //     type: 'link',
                //     link: '/CQC/InspectionAreaTaxonomy'
            },],
        }, {
            id: 5,
            menu_name: 'Sharable Links',
            type: 'link',
            child: [],
            link: '/SharableLinks'
            // }, {
            //     id: 4,
            //     menu_name: 'Regulators',
            //     type: 'link',
            //     child: [],
            //     link: ''
            // }, {
            //     id: 5,
            //     menu_name: 'Attorneys',
            //     type: 'link',
            //     child: [],
            //     link: ''
        }]
    }
    return (
        <>
            <nav className="navbar align-items-start sidebar sidebar-dark accordion bg-gradient-architecture navbar-dark sidebar-hidden-xs">
                <div className="container-fluid flex-column p-0"><NavLink className="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0" to="/Architecture">
                    <div className="sidebar-brand-icon">
                        <div className='card'>
                            <div className='card-body p-2'>
                                <img src={Logo} style={{ height: '25px' }} />
                            </div>
                        </div>
                    </div>
                </NavLink>
                    <hr className="sidebar-divider my-0" />
                    <SidebarMenus />
                    <hr class="sidebar-divider my-0"></hr>
                    <ul className="w-100pc navbar-nav text-light" id="accordionSidebar">
                        <li className="nav-item" onClick={() => { ReactSession.set("login_status", 0); window.location.reload(); }}>
                            <NavLink className="nav-link indent-18px" to="#">
                                <span>Logout</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
            <div className='container-fluid p-fixed-navigation'>
                <div className='btn mt-2' onClick={() => setShowMenu(!ShowMenu)}><i className='fa fa-bars'></i></div>
                {ShowMenu ?
                    <div className='fixed-navigation'>
                        <div className='container-fluid'>
                            <div className='btn mt-2 float-right mr-5' onClick={() => setShowMenu(!ShowMenu)}><i className='fa fa-times'></i></div>
                        </div>
                        <div className='container-fluid'>
                            <div className='row d-flex justify-space-between mt-5'>
                                {!ShowSubMenu ?
                                    <div className='row'>
                                        {SidebarMenuJSON && SidebarMenuJSON?.items && SidebarMenuJSON?.items?.length > 0 ?
                                            SidebarMenuJSON?.items?.map((val, index) => (
                                                <MobileSidebar val={val} setShowSubMenu={setShowSubMenu} setSubMenu={setSubMenu} />
                                            ))
                                            : ''}
                                        <div className='col-xs-4 col-sm-4 mt-2 mb-2'>
                                            <div className='card' onClick={() => { ReactSession.set("login_status", 0); window.location.reload(); }}>
                                                <div className='card-body'>
                                                    <div className='row'>
                                                        <div className='container-fluid d-block'>
                                                            <div className='row d-flex flex-direction-row justify-space-between'>
                                                                <div className='w-auto'>
                                                                    Logout
                                                                </div>
                                                                <div className='w-auto'>
                                                                    <p><i className='fa fa-angle-right' /></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='row'>
                                        {SubMenu && SubMenu?.length > 0 ?
                                            SubMenu?.map((val, index) => (
                                                <MobileSidebar val={val} setShowSubMenu={setShowSubMenu} setSubMenu={setSubMenu} />
                                            ))
                                            : ''}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    : ''}
            </div>
        </>
    )
}
