import React, { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

import { Loader, Placeholder } from 'rsuite';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ['Cancelled', 'Early', 'Late', 'Missed', 'onTime', 'overall', 'pending', 'unallocated'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        'rgba(75, 192, 192, 0.2)',
        'rgba(50, 50, 50, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
      ],
      borderColor: [
        'rgba(75, 192, 192, 1)',
        'rgba(40, 40, 40, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

export default function ServerVisitManagementGraph({ titleTemp, graphData }) {

  // console.log("graphData", graphData, titleTemp)

  const options = {
    type: 'doughnut',
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: titleTemp,
      },
    },
  };


  const [loading, setLoading] = useState(false);
  const [GraphData, setGraphData] = useState(data);
  useEffect(() => {
    let temp = graphData ? JSON.parse(graphData) : {};
    // console.log(temp)
    let tempLabels = [];
    let tempGraphData = [];
    temp.forEach(element => {
      tempLabels.push(element[0]);
      tempGraphData.push(element[1]);
    });
    let typeBorderColors = {
      "Individual": 'rgba(212, 212, 212, 1)',
      "End of life": 'rgba(191, 64, 191, 1)',
      "Extremely at risk": 'rgba(255, 127, 80, 1)',
      "In hospital": 'rgba(255, 234, 0, 1)',
      "On location": 'rgba(175, 31, 37, 1)',
      "Out of the House": 'rgba(0, 191, 255, 1)',
    };
    let typeBackgroundColors = {
      "Individual": 'rgba(212, 212, 212, 0.2)',
      "End of life": 'rgba(191, 64, 191, 0.2)',
      "Extremely at risk": 'rgba(255, 127, 80, 0.2)',
      "In hospital": 'rgba(255, 234, 0, 0.2)',
      "On location": 'rgba(175, 31, 37, 0.2)',
      "Out of the House": 'rgba(0, 191, 255, 0.2)',
    };

    if (graphData) {
      const data = {
        labels: tempLabels,
        datasets: [
          {
            label: 'Count',
            data: tempGraphData,
            backgroundColor: [
              typeBackgroundColors[tempLabels[0]],
              typeBackgroundColors[tempLabels[1]],
              typeBackgroundColors[tempLabels[2]],
              typeBackgroundColors[tempLabels[3]],
              typeBackgroundColors[tempLabels[4]],
              typeBackgroundColors[tempLabels[5]],
            ],
            borderColor: [
              typeBorderColors[tempLabels[0]],
              typeBorderColors[tempLabels[1]],
              typeBorderColors[tempLabels[2]],
              typeBorderColors[tempLabels[3]],
              typeBorderColors[tempLabels[4]],
              typeBorderColors[tempLabels[5]],
            ],
            borderWidth: 1,
          },
        ],
      };
      setGraphData(data);
    }
  }, [graphData])


  let printDocument = () => {
    const input = document.getElementById('visitManagement3');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'JPEG', 0, 0);
        // pdf.output('dataurlnewwindow');
        pdf.save("download.pdf");
      })
      ;
  }


  return (
    <>
      {loading ?
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
        :
        <div className='container-fluid'>
          <div className='row'>
            <div className='card'>
              <div className="row mt-2 text-right"><i onClick={printDocument} className='fa fa-download'></i></div>
              <div className='card-body' id='visitManagement3'>
                <div className='row'>
                  <Doughnut options={options} data={GraphData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}
