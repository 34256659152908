import React, { useState } from 'react'

export default function AssignActivityPopup({ post, ModalId, setModalId, TabId, setTabId, showModalAndTab }) {
    const [FilterPayload, setFilterPayload] = useState({});

    const handleChange = e => {
        const { name, value } = e?.target;
        setFilterPayload({
            ...FilterPayload,
            [name]: value
        })
    }

    const handleChecked = e => {
        const { name, checked, value } = e?.target;
        if (checked) {
            setFilterPayload({
                ...FilterPayload,
                [name]: value
            })
        } else {
            setFilterPayload({
                ...FilterPayload,
                [name]: ''
            })
        }
    }

    return (
        <>
            <div className={`modal fade ${ModalId == 'assignActivityPopup' ? 'show' : ''}`} id="assignActivityPopup" tabindex="-1" role="dialog" aria-labelledby="assignActivityPopupLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="assignActivityPopupLabel">Activity</h5>
                            <button type="button" className="close" onClick={() => showModalAndTab('', '')}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: "50vh", overflowY: "scroll" }}></div>
                    </div>
                </div>
            </div>
        </>
    )
}
