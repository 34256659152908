import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Loader, Placeholder } from 'rsuite';

import postAPI from './api';

import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { useParams } from 'react-router-dom';
import VisitFilter from './VisitFilter';
import VisitAction from './VisitAction';
import AssignActivityPopup from './popups/AssignActivityPopup';
import AssignEmployeeModal from './popups/AssignEmployeeModal';
import AssignMedicationPopup from './popups/AssignMedicationPopup';
import CreateNewCareDaysModal from './popups/CreateNewCareDaysModal';
import CreateNewEmployeeLeaveModal from './popups/CreateNewEmployeeLeaveModal';
import CreateNewEmployeeWorkDayModal from './popups/CreateNewEmployeeWorkDayModal';
import CreateNewEventModal from './popups/CreateNewEventModal';
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;



export default function VisitCalendar() {
  const [FilterFor, setFilterFor] = useState('STAFF');
  const { server_id, admin_id } = useParams();
  const [Employee, setEmployee] = useState([]);
  const [ServiceUser, setServiceUser] = useState([]);
  const [ModalId, setModalId] = useState('');
  const [TabId, setTabId] = useState('');
  const [calendar, setCalendar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [API_URL, setAPI_URL] = useState("https://testapi.architecture.care");

  const calendarRef = useRef(null);



  const formatTime = (time) => {
    const parts = time.split(":");
    if (parseInt(parts[0]) < 10 && parseInt(parts[0]) > 0) {
      parts[0] = "0" + parts[0];  // Ensure hour is two digits
    }
    return parts.join(":");
  }

  function showModalAndTab(modalId, tabId) {
      setModalId(modalId);
      setTabId(tabId);
  }

  const post = async (endpoint, filter) => {
    try {
      const response = await postAPI(endpoint, filter); // Use `filter` instead of `FormData`
      console.log(response);
      if (response?.res === 1) {
        return response; // Return response so caller can use it
      } else {
        return { res: 0, error: response?.error };
      }
    } catch (e) {
      const errorMessage = e?.response?.data?.error || "Something went wrong";
      console.log("Exception Error:", errorMessage);
      return { res: 0, error: errorMessage }; // Return error to caller
    }
  };

  const fetchEmployeeList = async () => {
    const endpoint = "employees/list";
    const filter = {
      server_id: server_id
    };
    try {
      const response = await post(endpoint, filter);
      const data = response?.data;
      setEmployee(data);
    } catch (error) {
      console.error('Error fetching data:', error);  // Handle any errors
    }
  }

  const fetchServiceUsersList = async () => {
    const endpoint = "service_users/list";
    const filter = {
      server_id: server_id
    };
    try {
      const response = await post(endpoint, filter);
      const data = response?.data;
      setServiceUser(data);
    } catch (error) {
      console.error('Error fetching data:', error);  // Handle any errors
    }
  }




  useEffect(() => {
    fetchEmployeeList();
    fetchServiceUsersList();
    if (calendarRef.current) {
      const tempcalendar = new Calendar(calendarRef.current, {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: 'prev,next',
          center: 'title',
          right: 'today timeGridWeek,timeGridDay'
        },
        initialView: 'timeGridWeek',
        themeSystem: 'bootstrap',
        selectable: true,
        editable: true,
        droppable: true,
        // eventClick: function (info) {
        //   eventClickHandlar(info)
        // },
        eventMouseEnter: function (info) { },
        eventMouseLeave: function (info) { },
        // eventDrop: function (info) {
        //   eventDropHandlar(info)
        // },
        // eventResize: function (info) {
        //   eventResizeHandlar(info)
        // }
      })
      setCalendar(tempcalendar)
      tempcalendar.render()
      return () => {
        tempcalendar.destroy();
      };
    }
  }, [])


  return (
    <>
      {loading ? (
        <div>
          <Placeholder.Paragraph rows={8} />
          <Loader center content="loading" />
        </div>
      ) : (
        <>
          <div className="container-fluid card card-primary fc">
            <div className="row">

              <VisitFilter post={post} FilterFor={FilterFor} setFilterFor={setFilterFor} Employee={Employee} ServiceUser={ServiceUser} showModalAndTab={showModalAndTab} />

              <div className="col-md-6" id="CalendarPanel">
                <div className="row">
                  <div className="container-fluid">
                    <div className="container-fluid card-body customBtnGroup mt-3" style={{flexDirection: "row-reverse"}}>
                      {/* <div>
                        <button className="fc-button fc-button-primary" onClick={syncEvents}><i className="fa fa-sync"></i></button>
                      </div> */}
                      {
                        FilterFor == 'USER' ?
                          <div id="ServiceUserOptionBar">
                            <button className="fc-button fc-button-primary ml-2" onClick={() => showModalAndTab('createNewEventModal', '')}><i className="fa fa-plus"></i> New Schedule</button>
                            <button className="fc-button fc-button-primary ml-2" onClick={() => showModalAndTab('createNewCareDaysModal', '')}><i className="fa fa-plus"></i> New Care Day</button>
                          </div>
                        : FilterFor == 'STAFF' ?
                          <div id="EmployeeOptionBar">
                            <button className="fc-button fc-button-primary ml-2" onClick={() => showModalAndTab('createNewEmployeeWorkDayModal', '')}><i className="fa fa-plus"></i> New Work Day</button>
                            <button className="fc-button fc-button-primary ml-2" onClick={() => showModalAndTab('createNewEmployeeLeaveModal', '')}><i className="fa fa-plus"></i> New Leave</button>
                          </div>
                        : ''
                      }
                    </div>
                    <div className="container-fluid card-body">
                      <div id="calendar" ref={calendarRef}></div>
                    </div>
                  </div>
                </div>
              </div>


              <VisitAction post={post} FilterFor={FilterFor} setFilterFor={setFilterFor} ModalId={ModalId} setModalId={setModalId} TabId={TabId} setTabId={setTabId} showModalAndTab={showModalAndTab} />


            </div>
          </div>
        </>
      )}
      <AssignActivityPopup post={post} ModalId={ModalId} setModalId={setModalId} TabId={TabId} setTabId={setTabId} showModalAndTab={showModalAndTab} />
      <AssignEmployeeModal post={post} ModalId={ModalId} setModalId={setModalId} TabId={TabId} setTabId={setTabId} showModalAndTab={showModalAndTab} />
      <AssignMedicationPopup post={post} ModalId={ModalId} setModalId={setModalId} TabId={TabId} setTabId={setTabId} showModalAndTab={showModalAndTab} />

      <CreateNewEventModal post={post} ServiceUser={ServiceUser} ModalId={ModalId} setModalId={setModalId} TabId={TabId} setTabId={setTabId} showModalAndTab={showModalAndTab} />
      <CreateNewCareDaysModal post={post} ServiceUser={ServiceUser} ModalId={ModalId} setModalId={setModalId} TabId={TabId} setTabId={setTabId} showModalAndTab={showModalAndTab} />

      <CreateNewEmployeeLeaveModal post={post} Employee={Employee} ModalId={ModalId} setModalId={setModalId} TabId={TabId} setTabId={setTabId} showModalAndTab={showModalAndTab} />
      <CreateNewEmployeeWorkDayModal post={post} Employee={Employee} ModalId={ModalId} setModalId={setModalId} TabId={TabId} setTabId={setTabId} showModalAndTab={showModalAndTab} />

    </>
  );
}

